import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Sidebar } from 'primereact/sidebar';

type CalendarSettingsDrawerProps = {
  isVisible: boolean;
  onHide: VoidFunction;

  hidePatientName?: boolean;
  onHidePatientName?: (value: boolean) => void;

  showGoogleCalendarEvents?: boolean;
  onShowGoogleCalendarEvents?: (value: boolean) => void;

  showAthenaAppointments?: boolean;
  onShowAthenaAppointments?: (value: boolean) => void;

  showFullCalendar?: boolean;
  onShowFullCalendar?: (value: boolean) => void;

  showPatientBackgroundEvents?: boolean;
  onShowPatientBackgroundEvents?: (value: boolean) => void;

  showUnconfirmedAppointments?: boolean;
  onShowUnconfirmedAppointments?: (value: boolean) => void;

  showEmptyRooms?: boolean;
  isShowEmptyRoomsDisabled?: boolean;
  onShowEmptyRooms?: (value: boolean) => void;
};

export const CalendarSettingsDrawer = ({
  isVisible,
  onHide,
  hidePatientName,
  onHidePatientName,
  showGoogleCalendarEvents,
  onShowGoogleCalendarEvents,
  showAthenaAppointments,
  onShowAthenaAppointments,
  showFullCalendar,
  onShowFullCalendar,
  showPatientBackgroundEvents,
  onShowPatientBackgroundEvents,
  showUnconfirmedAppointments,
  onShowUnconfirmedAppointments,
  showEmptyRooms,
  isShowEmptyRoomsDisabled,
  onShowEmptyRooms,
}: CalendarSettingsDrawerProps) => {
  return (
    <Sidebar
      visible={isVisible}
      position='right'
      onHide={onHide}
      showCloseIcon={false}
      className='w-[500px]'
    >
      <header className='flex items-center justify-between py-3 border-b border-gray-300'>
        <h1 className='text-moss text-xl font-bold'>Calendar View Settings</h1>
        <Button
          icon='pi pi-times'
          rounded
          text
          aria-label='close'
          onClick={onHide}
          className='text-gray-400'
        />
      </header>

      <div className='py-4 flex flex-col gap-4'>
        {onHidePatientName && (
          <SettingItem
            label='Hide patient name'
            checked={hidePatientName!}
            onClick={onHidePatientName}
          />
        )}

        {onShowGoogleCalendarEvents && (
          <SettingItem
            label='Show Google Calendar events'
            checked={showGoogleCalendarEvents!}
            onClick={onShowGoogleCalendarEvents}
          />
        )}

        {onShowAthenaAppointments && (
          <SettingItem
            label='Show Athena appointments'
            checked={showAthenaAppointments!}
            onClick={onShowAthenaAppointments}
          />
        )}

        {onShowFullCalendar && (
          <SettingItem
            label='Full calendar'
            checked={showFullCalendar!}
            onClick={onShowFullCalendar}
          />
        )}

        {onShowPatientBackgroundEvents && (
          <SettingItem
            label='Show patient visits background'
            checked={showPatientBackgroundEvents!}
            onClick={onShowPatientBackgroundEvents}
          />
        )}

        {onShowUnconfirmedAppointments && (
          <SettingItem
            label='Show unconfirmed appointments'
            checked={showUnconfirmedAppointments!}
            onClick={onShowUnconfirmedAppointments}
          />
        )}

        {onShowEmptyRooms && (
          <SettingItem
            label='Show empty rooms'
            checked={showEmptyRooms!}
            onClick={onShowEmptyRooms}
            disabled={isShowEmptyRoomsDisabled}
          />
        )}
      </div>
    </Sidebar>
  );
};

const SettingItem = ({
  label,
  checked,
  onClick,
  disabled,
}: {
  label: string;
  checked: boolean;
  onClick: (checked: boolean) => void;
  disabled?: boolean;
}) => {
  return (
    <div className='flex items-center justify-between'>
      <p className='text-sm'>{label}</p>
      <InputSwitch
        className='ml-2'
        checked={checked}
        onChange={(e) => onClick(Boolean(e.value))}
        disabled={disabled}
      />
    </div>
  );
};
