import { Atria, Provider } from '@/@types';
import { DateTime } from 'luxon';

const getFormattedAppointmentChangeTitle = (
  currentAppointmentAudit: Atria.Appointment,
  currentKey: keyof Atria.Appointment
) => {
  const currentValue = currentAppointmentAudit[currentKey];

  if (typeof currentValue === 'boolean') return (currentValue as boolean).toString().toUpperCase();

  if (['end', 'date', 'deletedAt'].some((key) => key === currentKey)) {
    return DateTime.fromISO(currentValue as string).toFormat('EEE, MMMM dd - t');
  }

  if (Array.isArray(currentValue)) {
    return (currentValue as Array<{ name: string }>).map((el) => el.name).join(', ');
  }

  return currentValue?.toString() ?? '';
};

const checkIfValueIsEqual = (
  currentValue?: KeyValueAppointment | null,
  previousValue?: KeyValueAppointment | null
) => {
  const previousValueFormatted = Array.isArray(previousValue)
    ? JSON.stringify(previousValue)
    : previousValue;

  const currentValueFormatted = Array.isArray(currentValue)
    ? JSON.stringify(currentValue)
    : currentValue;

  return previousValueFormatted === currentValueFormatted;
};

const shouldDisplayChange = (
  currentValue?: KeyValueAppointment | null,
  previousValue?: KeyValueAppointment | null
): boolean => {
  const isEqualValue = checkIfValueIsEqual(currentValue, previousValue);

  return (
    !isEqualValue &&
    !!currentValue &&
    (!Array.isArray(currentValue) || (!!currentValue.length && !isEqualValue))
  );
};

const checkProviderChange = (type: 'EQUIPMENT' | 'LIAISON' | 'ADDITIONAL') => {
  return (
    currentValue?: KeyValueAppointment | null,
    previousValue?: KeyValueAppointment | null
  ) => {
    const currentProvidersForType =
      (currentValue as Provider[])?.filter((provider) => provider.type === type) ?? [];
    const previousProvidersForType =
      (previousValue as Provider[])?.filter((provider) => provider.type === type) ?? [];

    return !checkIfValueIsEqual(currentProvidersForType, previousProvidersForType);
  };
};

const checkAppointmentChange = (
  currentValue?: KeyValueAppointment | null,
  previousValue?: KeyValueAppointment | null
) => {
  const isEqual = checkIfValueIsEqual(currentValue, previousValue);

  const patientNotSelectedWhenAppointmentCreated =
    !currentValue && typeof previousValue === 'undefined';

  return !patientNotSelectedWhenAppointmentCreated && !isEqual;
};

const historyLogAttributes: {
  key: keyof Atria.Appointment;
  title: string;
  getFormattedChange: (
    currentAppointmentAudit: Atria.Appointment,
    key: keyof Atria.Appointment
  ) => string;
  checkChange: (
    currentValue?: KeyValueAppointment | null,
    previousValue?: KeyValueAppointment | null
  ) => boolean;
}[] = [
  {
    title: 'Title',
    key: 'title',
    checkChange: shouldDisplayChange,
    getFormattedChange: getFormattedAppointmentChangeTitle,
  },
  {
    title: 'Description',
    key: 'description',
    checkChange: shouldDisplayChange,
    getFormattedChange: getFormattedAppointmentChangeTitle,
  },
  {
    title: 'Duration',
    key: 'duration',
    checkChange: shouldDisplayChange,
    getFormattedChange: getFormattedAppointmentChangeTitle,
  },
  {
    title: 'Confirmed',
    key: 'confirmed',
    checkChange: (currentValue, previousValue) => !checkIfValueIsEqual(currentValue, previousValue),
    getFormattedChange: getFormattedAppointmentChangeTitle,
  },
  {
    title: 'Rooms',
    key: 'rooms',
    checkChange: shouldDisplayChange,
    getFormattedChange: getFormattedAppointmentChangeTitle,
  },
  {
    title: 'Appointment Type',
    key: 'type',
    checkChange: checkAppointmentChange,
    getFormattedChange: getFormattedAppointmentChangeTitle,
  },
  {
    title: 'Primary Provider',
    key: 'providerName',
    checkChange: checkAppointmentChange,
    getFormattedChange: getFormattedAppointmentChangeTitle,
  },
  {
    title: 'Patient',
    key: 'patientId',
    checkChange: checkAppointmentChange,
    getFormattedChange: (currentAppointmentAudit: Atria.Appointment) =>
      currentAppointmentAudit.patientId && currentAppointmentAudit.patientName
        ? currentAppointmentAudit.patientName
        : '',
  },
  {
    title: 'Liaison',
    key: 'providers',
    checkChange: checkProviderChange('LIAISON'),
    getFormattedChange: (currentAppointmentAudit) =>
      currentAppointmentAudit.providers
        ?.filter((provider) => provider.type === 'LIAISON')
        .map((el) => el.name)
        .join(', ') ?? '',
  },
  {
    title: 'Resources',
    key: 'providers',
    checkChange: checkProviderChange('EQUIPMENT'),
    getFormattedChange: (currentAppointmentAudit: Atria.Appointment) =>
      currentAppointmentAudit.providers
        ?.filter((provider) => provider.type === 'EQUIPMENT')
        .map((el) => el.name)
        .join(', ') ?? '',
  },
  {
    title: 'Additional',
    key: 'providers',
    checkChange: checkProviderChange('ADDITIONAL'),
    getFormattedChange: (currentAppointmentAudit: Atria.Appointment) =>
      currentAppointmentAudit.providers
        ?.filter((provider) => provider.type === 'ADDITIONAL')
        .map((el) => el.name)
        .join(', ') ?? '',
  },
  {
    title: 'Start Date',
    key: 'end',
    checkChange: shouldDisplayChange,
    getFormattedChange: getFormattedAppointmentChangeTitle,
  },
  {
    title: 'End Date',
    key: 'date',
    checkChange: shouldDisplayChange,
    getFormattedChange: getFormattedAppointmentChangeTitle,
  },
  {
    title: 'Deletion Date',
    key: 'deletedAt',
    checkChange: shouldDisplayChange,
    getFormattedChange: getFormattedAppointmentChangeTitle,
  },
];

type KeyValueAppointment =
  | string
  | number
  | boolean
  | Atria.Patient
  | Date
  | {
      id: number;
      name: string;
    }[]
  | Provider[]
  | Atria.Conflict[];

const getFormattedAppointmentAudits = (appointmentAudits: Atria.AppointmentAudit[]) =>
  appointmentAudits.map((currentAppointmentAudit, index) => {
    const date = DateTime.fromISO(currentAppointmentAudit.updatedAt);
    const previousAppointmentAudit = appointmentAudits[index + 1]?.dataNew ?? {};

    const changesMade = historyLogAttributes.reduce(
      (acc, { title, checkChange, getFormattedChange, key }) => {
        const currentValue = currentAppointmentAudit.dataNew[key];
        const previousValue = previousAppointmentAudit[key];

        if (!checkChange(currentValue, previousValue)) {
          return acc;
        }

        const formattedValue = getFormattedChange(currentAppointmentAudit.dataNew, key);

        return [
          ...acc,
          {
            title,
            value: formattedValue,
          },
        ];
      },
      [] as { title: string; value: string }[]
    );

    return {
      ...currentAppointmentAudit,
      formattedDate: date.toFormat('LLL d, T') + `${date.hour < 12 ? 'am' : 'pm'}`,
      changesMade: changesMade.map((change) => `${change.title} “${change.value}”`).join(', '),
    };
  });

export const SchedulerAppointmentHistoryHelper = {
  getFormattedAppointmentAudits,
};
