import { SelectButton, SelectButtonChangeEvent, SelectButtonProps } from 'primereact/selectbutton';
import { classNames } from 'primereact/utils';
import './ConfirmedStatus.css';
import { useCallback, useState } from 'react';
import { ConfirmedTypes } from '@/@types';

export type ConfirmedStatusProps = SelectButtonProps & { canClickOnHold?: boolean };

export const ConfirmedStatus = ({ canClickOnHold = true, ...props }: ConfirmedStatusProps) => {
  const [selectedValue, setSelectedValue] = useState(null);

  const handleChange = useCallback(
    (e: SelectButtonChangeEvent) => {
      if (e.value !== selectedValue) {
        setSelectedValue(e.value);
        props.onChange!(e);
      }
    },
    [props.onChange, setSelectedValue, selectedValue]
  );

  return (
    <div>
      <SelectButton
        {...props}
        optionDisabled={(option) =>
          !canClickOnHold && option.value === ConfirmedTypes.HOLD_CONFIRMED
        }
        onChange={handleChange}
        className={classNames(props.className, 'confirmed-status-select')}
      />
    </div>
  );
};
