import { AppointmentsSearch, Atria } from '@/@types';
import { appointmentsService } from '@/services';
import { useCallback } from 'react';

export const useAppointments = () => {
  const searchAppointments = useCallback(
    async (search: AppointmentsSearch): Promise<Atria.Appointment[]> => {
      const payload: Atria.FindAllAppointments.Params = {
        ...search,
        rangeQueryField: 'date',
        deleteStatus: 'not-deleted',
      };

      const { data: appointments } = await appointmentsService.findAppointmentsByRange(payload);
      return appointments;
    },
    []
  );

  const getAppointmentsByRange = useCallback(
    async (search: GetAppointmentByRangeParams): Promise<Atria.Appointment[]> => {
      const payload: Atria.FindAllAppointments.Params = {
        ...search,
      };

      const { data: appointments } = await appointmentsService.findAppointmentsByRange(payload);
      return appointments;
    },
    []
  );

  return {
    searchAppointments,
    getAppointmentsByRange,
  };
};

type GetAppointmentByRangeParams = {
  startDate: string;
  endDate: string;
};
