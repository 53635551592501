import React, { Component } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore:next-line
import Header from 'react-big-calendar/lib/Header';

interface HeaderProps {
  label?: React.ReactNode;
  localizer: Record<string, any>;
}

interface HeaderState {}

declare class IHeader extends Component<HeaderProps, HeaderState> {}

interface IHeaderConstructor {
  new (props: HeaderProps, state: HeaderState): IHeader;
}

export default Header as IHeaderConstructor;
