import {
  AppointmentType,
  AppointmentWithDate,
  AppointmentWithResourcesList,
  Atria,
  Provider,
  Resources,
  SelectOption,
} from '@/@types';
import { Room } from '@/@types/room';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { useEffect, useMemo, useState } from 'react';
import {
  CreateAppointmentForm,
  DuplicateMultipleAppointmentForm,
  EditAppointmentForm,
  EditMultipleAppointmentsForm,
} from '.';

type NewAppointmentFormMode = 'add' | 'edit' | 'duplicate';

export type NewAppointmentInformation = {
  start?: Date;
  end?: Date;
  resources?: Resources[];
};

type AppointmentFormProps = {
  data?:
    | AppointmentWithDate
    | AppointmentWithDate[]
    | AppointmentWithResourcesList
    | AppointmentWithResourcesList[];
  newAppointmentInfo?: NewAppointmentInformation;
  rooms: Room[];
  providers: Provider[];
  appointmentTypesList: AppointmentType[];
  mode: NewAppointmentFormMode;
  visible: boolean;
  handleOnHide: () => void;
  handleOnSubmit: (newAppointment?: Atria.Appointment | Atria.Appointment[]) => void;
};

export const AppointmentForm = ({
  data,
  mode,
  newAppointmentInfo,
  rooms,
  providers,
  visible,
  appointmentTypesList,
  handleOnHide,
  handleOnSubmit,
}: AppointmentFormProps) => {
  const roomsOptions = useMemo(() => rooms.map(({ id, name }) => ({ id, label: name })), [rooms]);

  const [personnelProviders, setPersonnelProviders] = useState<SelectOption[]>([]);
  const [nonPersonnelProviders, setNonPersonnelProviders] = useState<SelectOption[]>([]);

  useEffect(() => {
    const personnel: SelectOption[] = [];
    const nonPersonnel: SelectOption[] = [];

    providers.forEach((provider) => {
      if (provider.type === 'Person') {
        personnel.push({ id: provider.id, label: provider.name });
      } else {
        nonPersonnel.push({ id: provider.id, label: provider.name });
      }
    }, []);

    setPersonnelProviders(personnel);
    setNonPersonnelProviders(nonPersonnel);
  }, [providers]);

  const formTitle = useMemo(() => {
    switch (mode) {
      case 'edit':
        if (Array.isArray(data)) {
          return `Edit ${data?.length} appointments`;
        }
        return 'Edit appointment';
      case 'add':
        return 'Create appointment';
      case 'duplicate':
        if (!Array.isArray(data)) return;
        if (data.length > 1) {
          return `Duplicate ${data?.length} appointments`;
        }
        return 'Duplicate appointment';
    }
  }, [data, mode]);

  const renderForms = useMemo(() => {
    switch (mode) {
      case 'edit':
        if (!data) return;
        if (Array.isArray(data) && data.length > 1) {
          return (
            <EditMultipleAppointmentsForm
              roomsOptions={roomsOptions}
              personnelProviders={personnelProviders}
              nonPersonnelProviders={nonPersonnelProviders}
              appointments={data}
              onCancel={handleOnHide}
              onUpdate={handleOnSubmit}
              appointmentTypes={appointmentTypesList}
            />
          );
        }

        return (
          <EditAppointmentForm
            rooms={roomsOptions}
            personnelProviders={personnelProviders}
            nonPersonnelProviders={nonPersonnelProviders}
            appointment={Array.isArray(data) ? data[0] : data}
            onCancel={handleOnHide}
            onUpdate={handleOnSubmit}
            appointmentTypes={appointmentTypesList}
          />
        );
      case 'add':
        return (
          <CreateAppointmentForm
            rooms={roomsOptions}
            providers={providers}
            newAppointmentInfo={newAppointmentInfo}
            onCancel={handleOnHide}
            onSave={handleOnSubmit}
            appointmentTypes={appointmentTypesList}
          />
        );
      case 'duplicate':
        if (!Array.isArray(data)) return;
        if (data.length > 1) {
          return (
            <DuplicateMultipleAppointmentForm
              appointments={data as AppointmentWithDate[]}
              rooms={roomsOptions}
              providers={providers}
              appointmentTypes={appointmentTypesList}
              onCancel={handleOnHide}
              onSave={handleOnSubmit}
            />
          );
        }

        return (
          <CreateAppointmentForm
            defaultValues={data[0]}
            rooms={roomsOptions}
            providers={providers}
            onCancel={handleOnHide}
            onSave={handleOnSubmit}
            appointmentTypes={appointmentTypesList}
          />
        );
    }
  }, [
    appointmentTypesList,
    data,
    handleOnHide,
    handleOnSubmit,
    mode,
    newAppointmentInfo,
    nonPersonnelProviders,
    personnelProviders,
    providers,
    roomsOptions,
  ]);

  return (
    <Sidebar
      visible={visible}
      onHide={handleOnHide}
      position='right'
      showCloseIcon={false}
      blockScroll={true}
      className={'w-full md:w-[500px]'}
    >
      <header className='flex items-center justify-between py-1 border-b border-[var(--gray-1)]'>
        <h1 className='text-kelp font-bold text-lg'>{formTitle}</h1>
        <Button
          icon='pi pi-times'
          className='text-gray-5'
          rounded
          text
          aria-label='close'
          onClick={handleOnHide}
        />
      </header>
      <main>{renderForms}</main>
    </Sidebar>
  );
};
