import { AppointmentType } from '@/@types';

export function filterAppointmentTypesByPermission(
  appointmentTypes: AppointmentType[],
  hasPermissionToSeeTelehealth: boolean
) {
  if (hasPermissionToSeeTelehealth) {
    return appointmentTypes.filter(
      (type) =>
        type.name.toLowerCase().includes('tele') || type.name.toLowerCase().includes('phone call')
    );
  }

  return appointmentTypes;
}
