import { AppointmentWithDate, Atria, Origin } from '@/@types';

export const mountAppointmentObject = (appointment: Atria.Appointment): AppointmentWithDate => {
  return {
    ...appointment,
    appointmentId: appointment.id,
    date: new Date(appointment.date),
    end: new Date(appointment.end),
    updatedAt: new Date(appointment.updatedAt),
    provider: {
      id: appointment.providerId!,
      label: appointment.providerName!,
    },
    providers: appointment.providers.map(({ id, name, type }) => ({ id, name, type })),
    rooms: appointment.rooms.map(({ id, name: label }) => ({ id, label })),
    origin: appointment.atriaAppointment ? Origin.Atria : Origin.Athena,
  };
};
