import { useCallback, useMemo, useState } from 'react';
import { loader } from 'react-global-loader';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { SelectItem } from 'primereact/selectitem';

import { CustomSelect, SearchPatientByNameOrId } from '@/components';
import { Atria, Patient, Room, VisitTypeTemplates } from '@/@types';
import { SchedulerFilterHelper } from '@/helpers';
import { SelectFilterGroupHeader } from '@/components/SelectFilterWrapper/SelectFilterGroupHeader';

import './MockAppointmentsForm.css';
import { useToastContext } from '@/contexts';
import { useMockAppointments } from '../hooks';
import { DateTime } from 'luxon';

const now = DateTime.local();

type MockAppointmentsFormProps = {
  rooms: Room[];
  visitTypeTemplates: VisitTypeTemplates[];
};

export const MockAppointmentsForm = ({ rooms, visitTypeTemplates }: MockAppointmentsFormProps) => {
  const { toast } = useToastContext();
  const { createMockedAppointments } = useMockAppointments();
  const [startDate, setStartDate] = useState(
    now
      .startOf('minute')
      .plus({ minutes: 15 - (now.minute % 15) })
      .toJSDate()
  );
  const [selectedRooms, setSelectedRooms] = useState<Room[]>([]);
  const [selectedPatient, setSelectedPatient] = useState<Patient>();
  const [appointmentsAmount, setAppointmentsAmount] = useState(1);

  const isSubmitDisabled = useMemo(() => {
    return !startDate || selectedRooms.length === 0 || !selectedPatient || !appointmentsAmount;
  }, [appointmentsAmount, selectedPatient, selectedRooms.length, startDate]);

  const roomsOptionsGrouped = useMemo(() => {
    return SchedulerFilterHelper.groupOptions<Room>(
      rooms.sort((a, b) => a.displayOrder - b.displayOrder),
      { groupByField: 'type' }
    );
  }, [rooms]);

  const handleOnPatientSelect = useCallback((data: Atria.FindAllPatients.Response[0] | null) => {
    if (!data) {
      return;
    }
    setSelectedPatient({
      patientName: data.patientName,
      firstName: data.firstName,
      lastName: data.lastName,
      firstNameUsed: data.firstNameUsed,
      id: data.id,
      dob: data.dateOfBirth,
    });
  }, []);

  const onSubmit = useCallback(async () => {
    if (!selectedPatient) {
      return;
    }
    loader.show();

    try {
      await createMockedAppointments({
        date: startDate,
        rooms: selectedRooms,
        patient: selectedPatient,
        visitTypeTemplates: visitTypeTemplates.slice(0, appointmentsAmount),
        roomsOptionsList: rooms,
      });

      toast?.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Appointments created',
        life: 3000,
      });
    } catch {
      toast?.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to create appointments',
        life: 3000,
      });
    } finally {
      loader.hide();
    }
  }, [
    appointmentsAmount,
    createMockedAppointments,
    rooms,
    selectedPatient,
    selectedRooms,
    startDate,
    toast,
    visitTypeTemplates,
  ]);

  const onCancel = useCallback(() => {
    setStartDate(new Date());
    setSelectedRooms([]);
    setSelectedPatient(undefined);
    setAppointmentsAmount(1);
  }, []);

  return (
    <div className='mt-4 w-[500px]'>
      <CalendarPicker value={startDate} handleOnChange={(value: Date) => setStartDate(value)} />
      <RoomsField onChange={setSelectedRooms} options={roomsOptionsGrouped} value={selectedRooms} />
      <div className='mt-4'>
        <SearchPatientByNameOrId
          onSelect={handleOnPatientSelect}
          titleCss='text-fern font-bold mb-2'
          heightCss='rounded-[20px] py-3 px-5'
        />
      </div>
      <AppointmentsAmountField
        onChange={setAppointmentsAmount}
        options={visitTypeTemplates.map((_, index) => ({
          value: index + 1,
          label: `${index + 1}`,
        }))}
        value={appointmentsAmount}
      />

      <div className='mt-4 flex flex-col'>
        <Button disabled={isSubmitDisabled} label='Create' onClick={onSubmit} />
        <Button label='Cancel' className='mt-2' outlined onClick={onCancel} />
      </div>
    </div>
  );
};

type CalendarPickerProps = {
  value: Date;
  handleOnChange: (value: Date) => void;
};

const CalendarPicker = ({ value, handleOnChange }: CalendarPickerProps) => {
  return (
    <div className='flex flex-col'>
      <label htmlFor='startDate' className='text-fern font-bold mb-2'>
        Date:
      </label>
      <Calendar
        className='custom-calendar'
        inputId='startDate'
        hourFormat='12'
        name='startDate'
        onChange={(e) => handleOnChange(e.value as Date)}
        showTime
        stepMinute={5}
        value={value}
      />
    </div>
  );
};

type RoomsFieldProps = {
  onChange: (value: Room[]) => void;
  options: { label: string; options: Room[] }[];
  value: Room[];
};

const RoomsField = ({ onChange, options, value }: RoomsFieldProps) => {
  return (
    <div className='flex flex-col mt-4'>
      <label htmlFor='rooms' className='text-fern font-bold mb-2'>
        Rooms:
      </label>
      <CustomSelect
        className='w-full p-0 m-0'
        dataKey='id'
        display='chip'
        filter
        inputId='rooms'
        itemClassName='pl-7'
        onChange={(e) => onChange(e.value)}
        optionGroupChildren='options'
        optionGroupLabel='label'
        optionGroupTemplate={(groupProps) => (
          <SelectFilterGroupHeader
            {...groupProps}
            selectedState={value}
            optionGroupIdentify={'id'}
            onChange={(e) => {
              onChange(e);
            }}
          />
        )}
        optionLabel='name'
        options={options}
        placeholder='Select'
        resetFilterOnHide
        value={value}
      />
    </div>
  );
};

type AppointmentsAmountFieldProps = {
  onChange: (value: number) => void;
  options: SelectItem[];
  value: number;
};

const AppointmentsAmountField = ({ onChange, options, value }: AppointmentsAmountFieldProps) => {
  return (
    <div className='mt-4 flex flex-col'>
      <label htmlFor='appointmentsAmount' className='text-fern font-bold mb-2'>
        How many appointments would you like to create?
      </label>
      <Dropdown
        className='custom-dropdown'
        id='appointmentsAmount'
        onChange={(e) => onChange(e.value)}
        options={options}
        value={value}
      />
    </div>
  );
};
