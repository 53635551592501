export function checkIfValidProviderAppointment(appointment: {
  type?: string | null;
  atriaAppointment: boolean;
}) {
  const athenaTypeAllowList = ['home', 'tele', 'phone call'];
  return (
    appointment.atriaAppointment ||
    athenaTypeAllowList.some((t) => appointment.type?.toLowerCase().includes(t))
  );
}
