import { Atria } from '@/@types';
import { api } from '@/api';

function findAppointments(params: Atria.FindAllAppointments.Params) {
  return api.get<Atria.FindAllAppointments.Response>('/api/v1/appointments', { params });
}

function createAppointment(body: Atria.CreateAppointment.Body) {
  return api.post<Atria.CreateAppointment.Response>('/api/v1/appointments', body);
}

function duplicateAppointment(body: Atria.CreateAppointment.Body) {
  return api.post<Atria.CreateAppointment.Response>('/api/v1/appointments/duplicate', body);
}

function createMultipleAppointments(body: Atria.CreateMultipleAppointments.Body) {
  return api.post<Atria.CreateMultipleAppointments.Response>('/api/v1/appointments/multiple', body);
}

function updateAppointment(id: number, data: Atria.UpdateAppointment.Body) {
  return api.put<Atria.UpdateAppointment.Response>(`/api/v1/appointments/${id}`, data);
}

function updateAppointmentDnd(id: number, data: Atria.UpdateAppointment.Body) {
  return api.put<Atria.UpdateAppointment.Response>(`/api/v1/appointments/${id}/undo-dnd`, data);
}

function updateMultipleAppointments(data: Atria.UpdateMultipleAppointments.Body) {
  return api.put<Atria.UpdateMultipleAppointments.Response>(`/api/v1/appointments/multiple`, data);
}

function updateMultipleAppointmentsDnd(data: Atria.UpdateMultipleAppointments.Body) {
  return api.put<Atria.UpdateMultipleAppointments.Response>(
    `/api/v1/appointments/multiple/undo-dnd`,
    data
  );
}

function deleteAppointment(id: number) {
  return api.delete<boolean>(`/api/v1/appointments/${id}`);
}

function deleteMultipleAppointments(ids: string) {
  return api.delete<boolean>('/api/v1/appointments/multiple', { params: { ids } });
}

function findAppointmentTypes() {
  return api.get<Atria.FindAllAppointmentTypes.Response>('/api/v1/appointments/types');
}

function findAppointmentsByRange(params: Atria.FindAllAppointments.Params) {
  return api.get<Atria.FindAllAppointments.Response>('/api/v1/appointments', { params });
}

function restoreDeletedAppointments(body: Atria.RestoreDeletedAppointments.Body) {
  return api.post<Atria.RestoreDeletedAppointments.Response>('/api/v1/appointments/restore', body);
}

function findAppointmentHistory(appointmentId: number) {
  return api.get<Atria.FindAppointmentHistory.Response>(
    `/api/v1/appointments/${appointmentId}/history`
  );
}

function deleteConferenceLinkFromAppointment(appointmentId: number) {
  return api.delete<Atria.FindAppointmentHistory.Response>(
    `/api/v1/appointments/${appointmentId}/conference-link`
  );
}

export const appointmentsService = {
  findAppointments,
  createAppointment,
  duplicateAppointment,
  createMultipleAppointments,
  updateAppointment,
  updateMultipleAppointments,
  updateAppointmentDnd,
  updateMultipleAppointmentsDnd,
  deleteAppointment,
  deleteMultipleAppointments,
  findAppointmentTypes,
  findAppointmentsByRange,
  restoreDeletedAppointments,
  findAppointmentHistory,
  deleteConferenceLinkFromAppointment,
};
