import { DailyNotes } from '@/@types';
import { api } from '@/api';

async function findDailyNotesByDate(date: string, locationId: number) {
  return api.get<DailyNotes[]>('/api/v1/daily-notes', { params: { date, locationId } });
}

async function createDailyNotes(date: string, title: string, html: string, locationId: number) {
  return api.post<DailyNotes>('/api/v1/daily-notes', { date, title, html, locationId });
}

async function updateDailyNote(id: number, title: string, html: string) {
  return api.patch<DailyNotes>('/api/v1/daily-notes/' + id, { html, title });
}

async function deleteDailyNote(id: number) {
  return api.delete('/api/v1/daily-notes/' + id);
}

export const dailyNotesService = {
  findDailyNotesByDate,
  createDailyNotes,
  updateDailyNote,
  deleteDailyNote,
};
