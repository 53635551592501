import { AppointmentParsed, AppointmentType, Atria, SelectOption } from '@/@types';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { classNames } from 'primereact/utils';
import { useCallback, useEffect, useMemo } from 'react';
import { loader } from 'react-global-loader';
import { Controller, useForm } from 'react-hook-form';

import { useLocation, useToastContext } from '@/contexts';
import { appointmentsService } from '@/services';
import { confirmedTypesOptions } from '@/utils';
import { SearchPatientByNameOrId } from '@/components';
import {
  AppointmentFormWithoutTimeSchemaResolver,
  AppointmentWithoutTimeFormType,
} from './AppointmentFormSchema';
import { ConfirmedStatus } from './ConfirmedStatus';
import { FormField } from './FormField';

type EditMultipleAppointmentsFormProps = {
  roomsOptions: SelectOption[];
  personnelProviders: SelectOption[];
  nonPersonnelProviders: SelectOption[];
  appointments: AppointmentParsed[];
  appointmentTypes: AppointmentType[];
  onCancel: () => void;
  onUpdate: () => void;
};

export const EditMultipleAppointmentsForm = ({
  roomsOptions,
  personnelProviders,
  nonPersonnelProviders,
  appointments,
  appointmentTypes,
  onCancel,
  onUpdate,
}: EditMultipleAppointmentsFormProps) => {
  const { toast } = useToastContext();
  const { control, formState, watch, setValue, handleSubmit, reset } =
    useForm<AppointmentWithoutTimeFormType>({ resolver: AppointmentFormWithoutTimeSchemaResolver });
  const additionalProvidersSelected = watch('additionalProvidersSelected');
  const primaryProviderSelected = watch('primaryProviderSelected');
  const liaisonProviderSelected = watch('liaisonProviderSelected');
  const resourcesSelected = watch('resourcesSelected');
  const { selectedRegion } = useLocation();
  const hasSomeAthenaAppointment = useMemo(
    () => appointments.some((appt) => !appt.atriaAppointment || !!appt.athenaAppointmentId),
    [appointments]
  );

  const filteredProviders = useMemo(() => {
    return personnelProviders.filter((prov) => prov.id !== liaisonProviderSelected?.id);
  }, [liaisonProviderSelected?.id, personnelProviders]);

  const handleCancelFormButton = useCallback(() => {
    reset();
    onCancel();
  }, [reset, onCancel]);

  const removePreviousAppointmentTypeDefaultRoom = useCallback(() => {
    const appointmentTypesSelected = watch('appointmentType');

    if (!appointmentTypesSelected) {
      return;
    }
    const roomsSelected = watch('roomsSelected');
    const previousDefaultRoomsIds = new Set(
      appointmentTypesSelected.defaultRooms?.map(({ roomId }) => roomId) || []
    );
    const roomsSelectedWithoutPreviousDefaultRoom = roomsSelected?.filter(
      (room) => !previousDefaultRoomsIds.has(room.id)
    );
    setValue('roomsSelected', roomsSelectedWithoutPreviousDefaultRoom);
  }, [setValue, watch]);

  const addAppointmentTypeDefaultRoom = useCallback(
    (newType: AppointmentType) => {
      if (!newType || !newType.defaultRooms || newType.defaultRooms.length === 0) {
        return;
      }

      const roomsSelected = watch('roomsSelected');
      const defaultRoomsIds = new Set(newType?.defaultRooms?.map(({ roomId }) => roomId));
      const newSelectedRoomsIds = new Set([
        ...(roomsSelected?.map((room) => room.id) || []),
        ...defaultRoomsIds,
      ]);
      const newSelectedRooms = roomsOptions.filter((r) => newSelectedRoomsIds.has(r.id));
      return setValue('roomsSelected', newSelectedRooms);
    },
    [roomsOptions, setValue, watch]
  );

  const removePreviousAppointmentTypeDefaultResource = useCallback(() => {
    const appointmentTypesSelected = watch('appointmentType') as AppointmentType;

    if (!appointmentTypesSelected || !selectedRegion) {
      return;
    }

    const previousDefaultResourceIds: Set<number> = new Set();

    appointmentTypesSelected.defaultResources
      ?.filter((resource) => resource.locationId === selectedRegion.id)
      .forEach(({ providerId }) => {
        previousDefaultResourceIds.add(providerId);
      });

    const newSelectedEquipments = resourcesSelected?.filter(
      (resource) => !previousDefaultResourceIds.has(resource.id)
    );

    return setValue('resourcesSelected', newSelectedEquipments);
  }, [setValue, watch, resourcesSelected, selectedRegion]);

  const addAppointmentTypeDefaultResource = useCallback(
    (newType: AppointmentType) => {
      if (!newType) {
        return;
      }

      const defaultResourceIds: Set<number> = new Set();

      newType.defaultResources
        ?.filter((resource) => resource.locationId === selectedRegion?.id)
        .forEach(({ providerId }) => {
          defaultResourceIds.add(providerId);
        });

      const newSelectedResourceIds = new Set([
        ...(resourcesSelected?.map((resource) => resource.id) || []),
        ...defaultResourceIds.values(),
      ]);

      const newSelectedEquipments = nonPersonnelProviders.filter((r) =>
        newSelectedResourceIds.has(r.id)
      );
      return setValue('resourcesSelected', newSelectedEquipments);
    },
    [nonPersonnelProviders, setValue, resourcesSelected, selectedRegion]
  );

  const handleAppointmentTypeChange = useCallback(
    async (newType: AppointmentType) => {
      removePreviousAppointmentTypeDefaultRoom();
      addAppointmentTypeDefaultRoom(newType);
      removePreviousAppointmentTypeDefaultResource();
      addAppointmentTypeDefaultResource(newType);
      setValue('appointmentType', newType);
    },
    [
      addAppointmentTypeDefaultRoom,
      removePreviousAppointmentTypeDefaultRoom,
      removePreviousAppointmentTypeDefaultResource,
      addAppointmentTypeDefaultResource,
      setValue,
    ]
  );

  const onSubmit = useCallback(
    async (data: AppointmentWithoutTimeFormType) => {
      loader.show();
      try {
        const providers = [
          ...(data.additionalProvidersSelected?.map((p) => ({
            id: p.id,
            name: p.label,
            type: 'ADDITIONAL',
          })) || []),
          ...(data.resourcesSelected?.map((p) => ({
            id: p.id,
            name: p.label,
            type: 'EQUIPMENT',
          })) || []),
        ];

        if (data.liaisonProviderSelected) {
          providers.push({
            id: data.liaisonProviderSelected.id,
            name: data.liaisonProviderSelected.label,
            type: 'LIAISON',
          });
        }

        const selectedRooms = data.roomsSelected || [];
        const confirmed = data.appointmentConfirmed;

        const newDate = new Date(data.date!);
        let appointmentRooms: SelectOption[] = [];

        const atria: Atria.UpdateMultipleAppointments.Atria[] = appointments
          .filter((a) => a.atriaAppointment)
          .map((appointment) => {
            appointmentRooms = [...selectedRooms];
            if (appointment.typeId && appointmentRooms.length) {
              const appointmentType = appointmentTypes.find(
                (type) => type.id === appointment.typeId
              );
              if (
                appointmentType &&
                appointmentType.defaultRooms &&
                appointmentType.defaultRooms.length > 0
              ) {
                const locationDefaultRoom = roomsOptions.find((room) =>
                  appointmentType.defaultRooms?.map((r) => r.roomId).includes(room.id)
                );

                if (
                  locationDefaultRoom &&
                  !selectedRooms.find((room) => room.id === locationDefaultRoom.id)
                ) {
                  appointmentRooms = [...appointmentRooms, locationDefaultRoom];
                }
              }
            }

            newDate.setHours(appointment.date.getHours());
            newDate.setMinutes(appointment.date.getMinutes());
            const apptResult: Atria.UpdateMultipleAppointments.Atria = {
              id: appointment.id,
              title: data.title || appointment.title,
              description: data.description,
              date: newDate.toJSON() || undefined,
              typeId: data.appointmentType?.id,
              type: data.appointmentType?.name,
              ...(data.primaryProviderSelected && {
                providerId: data.primaryProviderSelected.id,
                providerName: data.primaryProviderSelected.label,
              }),
              ...(data.patient && {
                patient: {
                  id: data.patient?.id,
                  firstName: data.patient?.firstName,
                  lastName: data.patient?.lastName,
                  firstNameUsed: data.patient?.firstNameUsed,
                  primaryProviderId: data.patient.primaryProviderId,
                },
              }),
              confirmed,
              providers,
              duration: appointment.duration,
            };

            if (selectedRooms.length) {
              apptResult.roomsIds = appointmentRooms.map((room) => room.id);
            }
            return apptResult;
          });

        const athenaAppointments = appointments
          .filter((a) => !a.atriaAppointment)
          .map(({ id }) => id);

        const athena: Atria.UpdateMultipleAppointments.Athena[] = athenaAppointments.map((id) => ({
          id,
          confirmed: true, // Making every athena appointment confirmed
          roomsIds: appointmentRooms.map((room) => room.id),
        }));

        await appointmentsService.updateMultipleAppointments({ atria, athena });

        toast?.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Appointments updated successfully',
          life: 3000,
        });
        reset();
        onUpdate();
      } catch (error: any) {
        toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: error.response?.data?.message || 'Failed to update appointments',
          life: 3000,
        });
      } finally {
        loader.hide();
      }
    },
    [appointments, onUpdate, reset, toast, appointmentTypes, roomsOptions]
  );

  useEffect(() => {
    if (appointments.every((appt) => appt.confirmed === appointments[0].confirmed)) {
      return setValue('appointmentConfirmed', appointments[0].confirmed);
    }
  }, [appointments, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col'>
      <div className='flex mt-4'>
        <Controller
          control={control}
          name='date'
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={true}
              isRequired={true}
              label='Date'
              labelRef='date'
            >
              <Calendar
                {...field}
                inputId='date'
                placeholder='Date'
                dateFormat='mm/dd/yy'
                locale='en'
                minDate={new Date()}
                className={classNames('h-[37px]', {
                  'border-rust': !!fieldState.error,
                })}
                inputClassName={classNames({
                  'border-rust': !!fieldState.error,
                })}
              />
            </FormField>
          )}
        />
      </div>

      <div className='flex mt-4'>
        <Controller
          control={control}
          name='title'
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={true}
              isRequired={true}
              label='Title'
              labelRef='title'
              disabled={hasSomeAthenaAppointment}
            >
              <InputText
                {...field}
                id='title'
                placeholder='Title'
                value={field.value || ''}
                disabled={hasSomeAthenaAppointment}
                className={classNames('h-[37px]', {
                  'border-rust': !!fieldState.error,
                })}
              />
            </FormField>
          )}
        />
      </div>

      <div className='flex mt-4'>
        <Controller
          control={control}
          name='description'
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={true}
              isRequired={false}
              label='Description'
              labelRef='description'
              disabled={hasSomeAthenaAppointment}
            >
              <InputTextarea
                {...field}
                id='description'
                placeholder='Description'
                value={field.value || ''}
                disabled={hasSomeAthenaAppointment}
              />
            </FormField>
          )}
        />
      </div>

      <div className='flex mt-4'>
        <SearchPatientByNameOrId
          disabled={hasSomeAthenaAppointment}
          onSelect={(patient) => {
            if (!patient) return;
            setValue('patient', {
              id: patient.id,
              firstName: patient.firstName,
              lastName: patient.lastName,
              firstNameUsed: patient.firstNameUsed,
              patientName: patient.patientName,
              primaryProviderId: patient.primaryProvider?.id || null,
            });
          }}
        />
      </div>

      <div className='flex mt-4'>
        <Controller
          control={control}
          name='appointmentType'
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={true}
              isRequired={false}
              label='Appointment Type'
              labelRef='appointmentType'
              disabled={hasSomeAthenaAppointment}
            >
              <Dropdown
                inputId='appointmentType'
                placeholder='Select an appointment type'
                filter
                optionLabel='name'
                disabled={hasSomeAthenaAppointment}
                options={appointmentTypes.sort((a, b) => a.name.localeCompare(b.name))}
                onChange={(e) => handleAppointmentTypeChange(e.value)}
                value={field.value}
                focusInputRef={field.ref}
                showClear
              />
            </FormField>
          )}
        />
      </div>

      <div className='flex mt-4'>
        <Controller
          control={control}
          name='roomsSelected'
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={true}
              isRequired={true}
              label='Rooms'
              labelRef='roomsSelected'
            >
              <MultiSelect
                inputId='roomsSelected'
                placeholder='Select rooms'
                filter
                optionLabel='label'
                options={roomsOptions.sort((a, b) => a.label.localeCompare(b.label))}
                onChange={(e) => field.onChange(e.value)}
                onBlur={field.onBlur}
                value={field.value}
                className={classNames('h-[37px]', {
                  'border-rust': !!fieldState.error,
                })}
              />
            </FormField>
          )}
        />
      </div>

      <div className='flex mt-4'>
        <Controller
          control={control}
          name='primaryProviderSelected'
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={true}
              isRequired={false}
              label='Primary Provider'
              labelRef='primaryProviderSelected'
              disabled={hasSomeAthenaAppointment}
            >
              <Dropdown
                showClear
                disabled={hasSomeAthenaAppointment}
                inputId='primaryProviderSelected'
                placeholder='Select the primary provider'
                filter
                optionLabel='label'
                options={personnelProviders.sort((a, b) => a.label.localeCompare(b.label))}
                onChange={(e) => {
                  const provider = e.target.value;
                  field.onChange(provider);
                  setValue(
                    'additionalProvidersSelected',
                    additionalProvidersSelected?.filter((i) => i.id !== provider.id)
                  );
                }}
                value={field.value}
                focusInputRef={field.ref}
              />
            </FormField>
          )}
        />
      </div>

      <div className='flex mt-4'>
        <Controller
          control={control}
          name='additionalProvidersSelected'
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={true}
              isRequired={false}
              label='Additional Clinician'
              labelRef='additionalProvidersSelected'
              disabled={hasSomeAthenaAppointment}
            >
              <MultiSelect
                inputId='additionalProvidersSelected'
                placeholder='Select additional staff'
                disabled={hasSomeAthenaAppointment}
                filter
                optionLabel='label'
                options={filteredProviders.sort((a, b) => a.label.localeCompare(b.label))}
                onChange={(e) => field.onChange(e.value)}
                onBlur={field.onBlur}
                value={field.value}
              />
            </FormField>
          )}
        />
      </div>

      <div className='flex mt-4'>
        <Controller
          control={control}
          name='liaisonProviderSelected'
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={true}
              isRequired={false}
              label='Liaison'
              labelRef='liaisonProviderSelected'
              disabled={hasSomeAthenaAppointment}
            >
              <Dropdown
                showClear
                disabled={hasSomeAthenaAppointment}
                inputId='liaisonProviderSelected'
                placeholder='Select the liaison'
                filter
                optionLabel='label'
                options={
                  primaryProviderSelected
                    ? personnelProviders
                        .filter((p) => p.id !== primaryProviderSelected?.id)
                        .sort((a, b) => a.label.localeCompare(b.label))
                    : personnelProviders.sort((a, b) => a.label.localeCompare(b.label))
                }
                onChange={(e) => {
                  const provider = e.target.value;
                  field.onChange(provider);
                  setValue(
                    'additionalProvidersSelected',
                    additionalProvidersSelected?.filter((i) => i.id !== provider.id)
                  );
                }}
                value={field.value}
                focusInputRef={field.ref}
              />
            </FormField>
          )}
        />
      </div>

      <div className='flex mt-4'>
        <Controller
          control={control}
          name='resourcesSelected'
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={true}
              isRequired={false}
              label='Resources'
              labelRef='resourcesSelected'
              disabled={hasSomeAthenaAppointment}
            >
              <MultiSelect
                inputId='resourcesSelected'
                disabled={hasSomeAthenaAppointment}
                placeholder='Select resources'
                filter
                optionLabel='label'
                options={nonPersonnelProviders.sort((a, b) => a.label.localeCompare(b.label))}
                onChange={(e) => field.onChange(e.value)}
                onBlur={field.onBlur}
                value={field.value}
              />
            </FormField>
          )}
        />
      </div>

      <div className='flex mt-4'>
        <Controller
          control={control}
          name='appointmentConfirmed'
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={true}
              isRequired={false}
              label='Status:'
              labelRef='appointmentConfirmed'
              disabled={hasSomeAthenaAppointment}
            >
              <ConfirmedStatus
                value={field.value}
                onChange={(e) => field.onChange(e.value)}
                canClickOnHold={!appointments.some((a) => a.athenaAppointmentId)}
                optionLabel='name'
                options={confirmedTypesOptions}
              />
            </FormField>
          )}
        />
      </div>

      <div className='w-full flex flex-col gap-2 mt-4'>
        <Button
          label='Save'
          size='small'
          className='w-full'
          type='submit'
          disabled={Object.values(formState.errors).length > 0}
        />
        <Button
          label='Cancel'
          size='small'
          className='w-full'
          type='reset'
          onClick={handleCancelFormButton}
          outlined
        />
      </div>
    </form>
  );
};
