import { SelectOption } from '@/@types';

export function filterRoomsByPermission(
  rooms: SelectOption[],
  hasPermissionToSeeTelehealth: boolean
) {
  if (hasPermissionToSeeTelehealth) {
    return rooms.filter((room) => room.label.toLowerCase().includes('tele'));
  }

  return rooms;
}
