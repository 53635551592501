import { env } from '@/utils/constants';
const ATHENA_PRACTICE_ID = 24265;

/**
 * Return athena deeplink to patient chart
 * @param patientId
 * @returns
 */
export function getPatientAthenaChartUrl(patientId?: number | null) {
  if (!patientId || !env.APP_ATHENA_URL) {
    return '';
  }
  return `${env.APP_ATHENA_URL}/1/1/login/startoidc.esp?DEEPLINK=1&TARGETURL=/${ATHENA_PRACTICE_ID}/1/client/clientsummary.esp?ID=${patientId}`;
}
