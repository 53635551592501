import { AppointmentType, AppointmentWithDate, Atria } from '@/@types';
import { appointmentsService } from '@/services';
import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';

export const useScheduler = () => {
  const [appointmentTypesOptionsList, setAppointmentTypes] = useState<AppointmentType[]>([]);

  const getAppointmentTypes = useCallback(async () => {
    const { data: types } = await appointmentsService.findAppointmentTypes();
    setAppointmentTypes(types);
  }, []);

  const getAppointmentsByDay = useCallback(
    async (startDate: string, endDate: string): Promise<Atria.Appointment[]> => {
      const { data: appointments } = await appointmentsService.findAppointmentsByRange({
        startDate,
        endDate,
      });
      return appointments;
    },
    []
  );

  const deleteAppointment = useCallback(async (appointmentId: number) => {
    await appointmentsService.deleteAppointment(appointmentId);
  }, []);

  const deleteMultipleAppointments = useCallback(async (appointmentIds: number[]) => {
    await appointmentsService.deleteMultipleAppointments(appointmentIds.join(','));
  }, []);

  const updateAppointment = useCallback(
    async (appointmentId: number, data: AppointmentWithDate, isDnD = false) => {
      const formattedStart = DateTime.fromJSDate(data.date);
      const formattedEnd = DateTime.fromJSDate(data.end);
      const { minutes: duration } = formattedEnd.diff(formattedStart, 'minutes');
      const updateAction = isDnD
        ? appointmentsService.updateAppointmentDnd
        : appointmentsService.updateAppointment;

      await updateAction(appointmentId, {
        title: data.title,
        description: data.description,
        confirmed: data.confirmed,
        rooms: data?.rooms || [],
        date: data.date.toISOString(),
        duration,
        type: data.type,
        typeId: data.typeId,
        atriaAppointment: data.atriaAppointment,
        providers: data.providers,
        ...(data.provider && {
          providerId: data.provider.id,
          providerName: data.provider.label,
        }),
        ...(data.patientId && {
          patient: {
            id: data.patientId!,
            firstName: data.firstName!,
            lastName: data.lastName!,
            firstNameUsed: data.firstNameUsed,
          },
        }),
      });
    },
    []
  );

  const updateMultipleAppointments = useCallback(
    async (data: Atria.UpdateMultipleAppointments.Body) => {
      await appointmentsService.updateMultipleAppointments(data);
    },
    []
  );

  const updateMultipleAppointmentsDnd = useCallback(
    async (data: Atria.UpdateMultipleAppointments.Body) => {
      await appointmentsService.updateMultipleAppointmentsDnd(data);
    },
    []
  );

  const getDeletedAppointmentsHistory = useCallback(
    async (
      startDate: string,
      endDate: string,
      locationId: number
    ): Promise<Atria.Appointment[]> => {
      const { data: appointments } = await appointmentsService.findAppointmentsByRange({
        rangeQueryStart: startDate,
        rangeQueryEnd: endDate,
        rangeQueryField: 'deletedAt',
        deleteStatus: 'deleted',
        locationId,
      });
      return appointments;
    },
    []
  );

  const restoreDeletedAppointments = useCallback(
    async (appointmentIds: number[]): Promise<void> => {
      await appointmentsService.restoreDeletedAppointments({ appointmentIds });
    },
    []
  );

  return {
    appointmentTypesOptionsList,
    getAppointmentTypes,
    getAppointmentsByDay,
    deleteAppointment,
    deleteMultipleAppointments,
    updateAppointment,
    updateMultipleAppointments,
    updateMultipleAppointmentsDnd,
    getDeletedAppointmentsHistory,
    restoreDeletedAppointments,
  };
};
