export function getDateToLocaleString(date?: Date) {
  let convertedDate = new Date();

  if (date) {
    convertedDate = date;
  }

  return convertedDate.toLocaleString('en-US', {
    timeZone: 'America/New_York',
  });
}
