import { Atria, ConfirmedTypes } from '@/@types';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';

type AppointmentItemProps = {
  item: Atria.Appointment;
};

export const AppointmentItem = ({ item }: AppointmentItemProps) => {
  const [isAllContent, setAllContent] = useState(false);
  const formatDate = useMemo(() => {
    const startFormatted = DateTime.fromISO(item.date).toFormat('dd/LL/yyyy h:mma');
    const endFormatted = DateTime.fromISO(item.end).toFormat('h:mma');

    return `${startFormatted} - ${endFormatted}`;
  }, [item]);

  const toggleAccordion = () => {
    setAllContent(!isAllContent);
  };

  const additionalCinician = useMemo(() => {
    return item.providers.find((p) => p.type === 'ADDITIONAL')?.name || 'N/A';
  }, [item.providers]);

  const equipament = useMemo(() => {
    return item.providers.find((p) => p.type === 'EQUIPMENT')?.name || 'N/A';
  }, [item.providers]);

  const liaison = useMemo(() => {
    return item.providers.find((p) => p.type === 'LIAISON')?.name || 'N/A';
  }, [item.providers]);

  const statusIcon = useMemo(() => {
    switch (item.confirmed) {
      case ConfirmedTypes.FULLY_CONFIRMED:
        return 'check';
      case ConfirmedTypes.HOLD_CONFIRMED:
        return 'circle';
      case ConfirmedTypes.UNCONFIRMED:
        return 'circle-fill';
      default:
        return '';
    }
  }, [item.confirmed]);

  const statusText = useMemo(() => {
    switch (item.confirmed) {
      case ConfirmedTypes.FULLY_CONFIRMED:
        return 'Confirmed';
      case ConfirmedTypes.HOLD_CONFIRMED:
        return 'Hold';
      case ConfirmedTypes.UNCONFIRMED:
        return 'Not Confirmed';
      default:
        return '';
    }
  }, [item.confirmed]);

  return (
    <li className='mb-3 p-4 bg-product-sand-200 rounded-lg'>
      <p className='text-product-forest-100 font-semibold text-sm mb-2'>
        {item.title}
        <span className='ml-4 font-light text-xs'>{formatDate}</span>
        <button
          className='cursor-pointer px-2 float-right'
          data-pr-tooltip='details'
          data-pr-position='bottom'
          onClick={toggleAccordion}
        >
          <i
            className={classNames(
              'pi text-gray-700 pi-chevron-down',
              isAllContent && 'pi-chevron-up'
            )}
          ></i>
        </button>
      </p>
      <div className='grid grid-cols-2 leading-relaxed gap-4 text-product-grey-400 text-xs'>
        <div className='leading-relaxed'>
          <p>Patient: {item.patientName || 'N/A'}</p>
          <p>Appointment Type: {item.type || 'N/A'}</p>
          <p>Provider: {item.providerName || 'N/A'}</p>
        </div>
        <div>
          <p className='max-w-xs text-ellipsis overflow-hidden ...'>
            Rooms: {item.rooms.map((room) => room.name).join(', ') || 'N/A'}
          </p>
          <div className='flex'>
            <span>
              <i className={`pi pi-${statusIcon} text-sm`}></i>
            </span>
            <p className='pl-1'>{statusText}</p>
          </div>
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: isAllContent ? 'auto' : 0 }}
            transition={{ duration: 0.3 }}
            className='overflow-hidden'
          >
            <p>Add. Clinician: {additionalCinician}</p>
          </motion.div>
        </div>
      </div>
      <motion.div
        initial={{ height: 0 }}
        animate={{ height: isAllContent ? 'auto' : 0 }}
        transition={{ duration: 0.3 }}
        className='overflow-hidden'
      >
        <div className='grid grid-cols-2 gap-4 text-product-grey-400 text-xs'>
          <p>Equipament: {equipament}</p>
          <p>Liaison: {liaison}</p>
        </div>
        <div className='text-product-grey-400 text-xs'>
          <div className='mt-1'>
            <p>Description: {item.description || 'N/A'}</p>
          </div>
        </div>
      </motion.div>
    </li>
  );
};
