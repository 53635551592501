import { LocalStorageData } from './localStorageTypes';

function setItem(data: LocalStorageData): void {
  if (window === undefined || !window.localStorage) return;

  window.localStorage.setItem(
    data.key,
    JSON.stringify({ value: data.value, expiresIn: data?.expiresIn })
  );
  window.dispatchEvent(new Event(data.key));
}

function getItem<Y = LocalStorageData['value']>(key: LocalStorageData['key']): Y | undefined {
  if (window === undefined || !window.localStorage) return undefined;

  const item = window.localStorage.getItem(key as string);
  if (!item) return undefined;

  const parsed = JSON.parse(item) as LocalStorageData;
  if (parsed.expiresIn && parsed.expiresIn < Date.now()) {
    removeItem(key);
    return undefined;
  }

  return parsed.value as Y;
}

function removeItem(key: LocalStorageData['key']): void {
  if (window === undefined || !window.localStorage) return;

  window.localStorage.removeItem(key);
  window.dispatchEvent(new Event(key));
}

export const LocalStorageHelper = {
  setItem,
  getItem,
  removeItem,
};
