import { AppointmentEventType, AppointmentParsed, googleWorkingTypeToLocationMap } from '@/@types';
import { ExtendButton } from '../ExtendButton';

export const CustomResourceHeader = ({
  resourceId,
  label,
  index,
  isAllDay,
  isExtendedColumn,
  handleColumnSizing,
  events,
  outOfOfficeEventId,
}: {
  resourceId: string;
  label: string;
  index: number;
  isAllDay: boolean;
  isExtendedColumn: boolean;
  handleColumnSizing: (index: string | number, decreaseColumn?: boolean) => void;
  events: (AppointmentParsed & {
    resourceId?: string;
  } & AppointmentEventType)[];
  outOfOfficeEventId: number | undefined;
}) => {
  const isPodOrProvider = resourceId?.includes('cmo') || resourceId?.includes('provider');

  if (isAllDay) {
    return (
      <div className='flex flex-col gap-1 mt-2'>
        <ExtendButton
          index={index}
          isPodOrProvider={isPodOrProvider}
          isExtendedColumn={isExtendedColumn}
          handleColumnSizing={handleColumnSizing}
        />

        <div className='flex flex-col flex-wrap justify-center gap-1'>
          <div>{label}</div>

          <div className='flex flex-wrap justify-center items-center gap-3'>
            {events?.map((event, eventIndex) => (
              <div className='flex items-center justify-center' key={`all-day-event-` + eventIndex}>
                {event?.workingLocationType &&
                  googleWorkingTypeToLocationMap[event?.workingLocationType] && (
                    <div>
                      <div className="text-white text-[10px] font-normal font-['Inter'] bg-[#869D89] rounded-[10px] px-2 py-[3px]">
                        {googleWorkingTypeToLocationMap[event?.workingLocationType]}
                      </div>
                    </div>
                  )}

                {event.isOutOfOffice && event.id === outOfOfficeEventId && (
                  <div className='text-[#F00] font-bold text-xs'>Out of Office</div>
                )}

                {!event?.workingLocationType && !event.isOutOfOffice && (
                  <div className='text-[#757575] font-normal text-xs'>{event.title}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='flex flex-col gap-1 mt-2'>
      <ExtendButton
        index={index}
        isPodOrProvider={isPodOrProvider}
        isExtendedColumn={isExtendedColumn}
        handleColumnSizing={handleColumnSizing}
      />

      <div className='custom-resource-header flex items-end flex-col gap-6'>
        <div className='self-center border-b border-[#ddd]'>
          <h1>{label}</h1>
        </div>
      </div>
    </div>
  );
};
