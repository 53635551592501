import { DateTime } from 'luxon';

export function getRandomTime(date: Date) {
  const randomHour = Math.floor(Math.random() * (19 - 7 + 1)) + 7;

  const possibleMinutes = [0, 15, 30, 45];
  const randomMinutes = possibleMinutes[Math.floor(Math.random() * possibleMinutes.length)];

  const randomTime = DateTime.fromJSDate(date).set({
    hour: randomHour,
    minute: randomMinutes,
    second: 0,
    millisecond: 0,
  });

  return randomTime;
}
