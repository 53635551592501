import React, { Component } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore:next-line
import ResourceHeader from 'react-big-calendar/lib/ResourceHeader';

interface ResourceHeaderProps {
  label?: React.ReactNode;
  index?: number;
  resource?: Record<string, any>;
}

interface ResourceHeaderState {}

declare class IResourceHeader extends Component<ResourceHeaderProps, ResourceHeaderState> {}

interface IResourceHeaderConstructor {
  new (props: ResourceHeaderProps, state: ResourceHeaderState): IResourceHeader;
}

export default ResourceHeader as IResourceHeaderConstructor;
