import { useCallback, useState } from 'react';

import { AppointmentFilterResourceItem, AppointmentFiltersChange, Atria } from '@/@types';
import { SelectFilterWrapper } from '../SelectFilterWrapper';
import { SearchPatientByNameOrId } from '@/components';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Nullable } from 'primereact/ts-helpers';

export type AppointmentFiltersProps = {
  providersOptionsList: AppointmentFilterResourceItem[];
  appointmentTypesOptionsList: AppointmentFilterResourceItem[];
  onResourcesChange: (filters: AppointmentFiltersChange) => void;
};

export const AppointmentFilters = ({
  providersOptionsList,
  appointmentTypesOptionsList,
  onResourcesChange,
}: AppointmentFiltersProps) => {
  const [appointmentFilters, setAppointmentFilters] = useState<AppointmentFiltersChange>({});

  const handlePatientOnChange = useCallback(
    async (patient: Atria.FindAllPatients.Response[0] | null) => {
      setAppointmentFilters({ ...appointmentFilters, patientId: patient?.id.toString() });
    },
    [setAppointmentFilters, appointmentFilters]
  );

  const handleChangeDate = useCallback(
    (field: 'startDate' | 'endDate', value?: Nullable<string | Date | Date[]>) => {
      setAppointmentFilters({
        ...appointmentFilters,
        [field]: value as Date,
      });
    },
    [appointmentFilters]
  );

  const submit = useCallback(() => {
    onResourcesChange(appointmentFilters);
  }, [appointmentFilters, onResourcesChange]);
  const isSearchEnabled = !!(
    appointmentFilters.startDate ||
    appointmentFilters.endDate ||
    appointmentFilters.patientId ||
    appointmentFilters.providers?.length ||
    appointmentFilters.appointmentTypes?.length
  );

  return (
    <>
      <h3 className='font-sans text-kelp font-semibold text-2xl mt-2'>Filters</h3>
      <div className='mt-4'>
        <SearchPatientByNameOrId
          titleCss='font-sans font-semibold text-sm text-product-grey-400 py-1 block'
          heightCss='h-[45px] pt-1'
          onSelect={(value) => handlePatientOnChange(value)}
        />
      </div>
      <div className='mt-2'>
        <SelectFilterWrapper
          title='Clinician'
          label='resourceTitle'
          options={providersOptionsList}
          dataKey='resourceId'
          onChange={(providers) => setAppointmentFilters({ ...appointmentFilters, providers })}
          placeholder='Select'
          value={appointmentFilters.providers || []}
          className='font-sans font-semibold text-sm text-product-grey-400 py-1 block'
        />
      </div>
      <div>
        <SelectFilterWrapper
          title='Appointment Types'
          label='resourceTitle'
          options={appointmentTypesOptionsList}
          dataKey='resourceId'
          onChange={(appointmentTypes) =>
            setAppointmentFilters({ ...appointmentFilters, appointmentTypes })
          }
          placeholder='Select'
          value={appointmentFilters.appointmentTypes || []}
          className='font-sans font-semibold text-sm text-product-grey-400 py-1 block'
        />
      </div>
      <div className='flex items-end justify-start gap-2'>
        <div className='flex-1'>
          <label className='font-sans font-semibold text-sm text-product-grey-400 py-1 block'>
            Start Date
          </label>
          <Calendar
            id='startDate'
            placeholder='Start Date'
            dateFormat='mm/dd/yy'
            locale='en'
            onChange={(value) => handleChangeDate('startDate', value.target.value)}
            value={appointmentFilters.startDate}
            className='h-[45px] w-full'
          />
        </div>
        <div className='mt-2 flex-1'>
          <label className='font-sans font-semibold text-sm text-product-grey-400 py-1 block'>
            End Date
          </label>
          <Calendar
            id='endDate'
            placeholder='End Date'
            dateFormat='mm/dd/yy'
            locale='en'
            onChange={(value) => handleChangeDate('endDate', value.target.value)}
            value={appointmentFilters.endDate}
            className='h-[45px] w-full'
          />
        </div>
      </div>
      <div className='mt-4'>
        <Button
          type='button'
          icon='pi pi-search'
          label='Search'
          size='small'
          onClick={submit}
          disabled={!isSearchEnabled}
          className='w-full h-[45px]'
        />
      </div>
    </>
  );
};
