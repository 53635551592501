import ExtendIcon from '@/assets/icons/expand.svg';
import GroupIcon from '@/assets/icons/group.svg';

export const ExtendButton = ({
  isPodOrProvider,
  isExtendedColumn,
  index,
  handleColumnSizing,
}: {
  isPodOrProvider: boolean;
  isExtendedColumn: boolean;
  index: number | string;
  handleColumnSizing: (index: number | string, isExtendedColumn: boolean) => void;
}) => (
  <div className='flex justify-end'>
    {isPodOrProvider && (
      <img
        src={isExtendedColumn ? GroupIcon : ExtendIcon}
        alt='increase or decrease the size of the current column'
        className='cursor-pointer mr-1'
        onClick={() => handleColumnSizing(index, isExtendedColumn ? true : false)}
      />
    )}
  </div>
);
