import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { luxonLocalizer, Views } from 'react-big-calendar';
import { CustomWeek } from './CustomWeek';
import {
  AppointmentParsed,
  AppointmentType,
  AppointmentWithDate,
  AppointmentWithResourcesList,
  isPatientVisit,
  PatientVisit,
  Provider,
  Resources,
  Room,
  VisitTypeTemplates,
} from '@/@types';
import { CustomColumnHeaderProps, CustomToolbarProps } from '@/features/Calendar';

import './WeeklyCalendar.css';
import CustomWithDragAndDrop from '@/features/Calendar/lib/react-big-calendar/lib/addons/customWithDragAndDrop/CustomWithDragAndDrop';
import { Toolbar } from '@/features/Calendar/components';

import { CalendarBackgroundEvent, CalendarEvent } from '@/components';
import { SchedulerConstants } from '@/utils/constants';
import { getAppointmentCardClasses, getPatientVisitClasses } from '../../utils';
import { ExtendButton } from '@/components/ExtendButton';

const localizer = luxonLocalizer(DateTime);

type WeeklyCalendarProps = {
  appointmentTypesList: AppointmentType[];
  currentDate: Date;
  events: any[];
  backgroundEvents: any[];
  showPatientBackgroundEvents: boolean;
  providersOptionsList: Provider[];
  onAppointmentClick: (event: AppointmentParsed) => void;
  onBackgroundAppointmentClick: (event: PatientVisit) => void;
  onCurrentDateChange: (date: Date) => void;
  onCalendarViewSettingsClick: VoidFunction;
  onNewAppointmentClick: VoidFunction;
  onToggleFiltersClick: VoidFunction;
  resources: Resources[];
  roomsOptionsList: Room[];
  showFullCalendar?: boolean;
  suiteRoomsIds: number[];
  visitTypeTemplates: VisitTypeTemplates[];
};

export const WeeklyCalendar = ({
  currentDate,
  events,
  onAppointmentClick,
  onBackgroundAppointmentClick,
  onCurrentDateChange,
  onCalendarViewSettingsClick,
  onNewAppointmentClick,
  onToggleFiltersClick,
  resources,
  roomsOptionsList,
  showFullCalendar,
  suiteRoomsIds,
  visitTypeTemplates,
  backgroundEvents,
  showPatientBackgroundEvents,
}: WeeklyCalendarProps) => {
  const [extendedColumns, setExtendedColumns] = useState<string[]>([]);
  const [calendarTimeRange, setCalendarTimeRange] = useState({
    start: DateTime.fromJSDate(currentDate)
      .set({ hour: 7, minute: 0, second: 0, millisecond: 0 })
      .toJSDate(),
    end: DateTime.fromJSDate(currentDate)
      .set({ hour: 19, minute: 0, second: 0, millisecond: 0 })
      .toJSDate(),
  });

  const handleColumnSizing = useCallback(
    (index: number | string, decreaseColumn = false) => {
      if (decreaseColumn) {
        return setExtendedColumns(extendedColumns.filter((column) => column !== index));
      }

      setExtendedColumns([...extendedColumns, index as string]);
    },
    [extendedColumns]
  );

  const showBackground = useMemo(() => {
    if (!showPatientBackgroundEvents) {
      return false;
    }

    return true;
  }, [showPatientBackgroundEvents]);

  const renderNewEvent = useCallback(
    ({ event }: CustomEventProps) => {
      if (isPatientVisit(event)) {
        if (!showBackground) {
          return;
        }

        return (
          <CalendarBackgroundEvent
            event={event}
            onSelectBackgroundEvent={onBackgroundAppointmentClick}
            hidePatientName={false}
          />
        );
      }

      return <CalendarEvent appointment={event} isWeeklyViewEvent={true} />;
    },
    [onBackgroundAppointmentClick, showBackground]
  );

  const renderColumnHeader = useCallback((props: CustomColumnHeaderProps) => {
    return <ColumnHeaderComponent date={props.date} />;
  }, []);

  const renderResourceHeader = useCallback(
    ({ label, resource, uniqueIndex }: ResourceHeaderComponentProps) => {
      const isExtendedColumn = !!extendedColumns.find((column) => column === uniqueIndex);

      return (
        <ResourceHeaderComponent
          label={label}
          resource={resource}
          uniqueIndex={uniqueIndex}
          isExtendedColumn={isExtendedColumn}
          handleColumnSizing={handleColumnSizing}
        />
      );
    },
    [extendedColumns, handleColumnSizing]
  );

  const renderToolbar = useCallback(
    (props: CustomToolbarProps) => (
      <Toolbar
        date={props.date}
        dateComponent={<ToolbarDateComponent date={props.date} />}
        hasCalendarPicker={false}
        hasDailyNotes={false}
        onCalendarViewSettingsClick={onCalendarViewSettingsClick}
        onNavigate={props.onNavigate}
        onNewAppointmentClick={onNewAppointmentClick}
        onToggleFiltersClick={onToggleFiltersClick}
        roomsOptionsList={roomsOptionsList}
        suiteRooms={suiteRoomsIds}
        visitTypeTemplates={visitTypeTemplates}
      />
    ),
    [
      onCalendarViewSettingsClick,
      onNewAppointmentClick,
      onToggleFiltersClick,
      roomsOptionsList,
      suiteRoomsIds,
      visitTypeTemplates,
    ]
  );

  const eventStyleGetter = useCallback((event: AppointmentWithResourcesList) => {
    if (isPatientVisit(event)) {
      return getPatientVisitClasses();
    }

    return getAppointmentCardClasses(event);
  }, []);

  const calendarProps = useMemo(
    () =>
      ({
        className: 'weekly-calendar',
        backgroundEvents,
        components: {
          event: renderNewEvent,
          header: renderColumnHeader,
          resourceHeader: renderResourceHeader,
          toolbar: renderToolbar,
        },
        date: currentDate,
        dayLayoutAlgorithm: 'no-overlap',
        defaultDate: currentDate,
        defaultView: Views.WEEK,
        endAccessor: 'end',
        eventPropGetter: eventStyleGetter,
        events: events,
        formats: {
          timeGutterFormat: (date: Date) => {
            return DateTime.fromJSDate(date).toFormat('h:mm a ');
          },
        },
        getNow: () => currentDate,
        localizer,
        max: calendarTimeRange.end,
        min: calendarTimeRange.start,
        onNavigate: onCurrentDateChange,
        onRangeChange: () => {
          return;
        },
        onSelectEvent: onAppointmentClick,
        resourceIdAccessor: 'resourceId',
        resources,
        resourceTitleAccessor: 'resourceTitle',
        scrollToTime: currentDate,
        showMultiDayTimes: true,
        startAccessor: 'date',
        step: SchedulerConstants.step,
        timeslots: SchedulerConstants.timeslots,
        tooltipAccessor: '' as any,
        views: {
          week: CustomWeek,
        },
      }) as any,
    [
      calendarTimeRange.end,
      calendarTimeRange.start,
      currentDate,
      eventStyleGetter,
      events,
      onAppointmentClick,
      onCurrentDateChange,
      renderColumnHeader,
      renderNewEvent,
      renderResourceHeader,
      renderToolbar,
      resources,
      backgroundEvents,
    ]
  );

  useEffect(() => {
    const timeHeaderColumns = document.querySelectorAll(
      '.weekly-calendar .rbc-time-view .rbc-time-header .rbc-time-header-content .rbc-time-header-cell .rbc-header'
    );

    const timeColumns = document.querySelectorAll(
      '.weekly-calendar .rbc-time-view .rbc-time-content .rbc-day-slot'
    );

    timeHeaderColumns.forEach((column, index) => {
      if (!column || !timeColumns[index]) return;

      column.classList.remove('extendedColumn');
      timeColumns[index].classList.remove('extendedColumn');
    });

    extendedColumns.forEach((index) => {
      const [headerIndex, columnIndex] = index.split('-').map(Number);
      const currentIndex = resources.length * headerIndex + columnIndex;
      const currentHeaderColumn = timeHeaderColumns[currentIndex];
      const currentColumn = timeColumns[currentIndex];

      if (!currentHeaderColumn || !currentColumn || !extendedColumns.includes(index)) return;

      currentHeaderColumn.classList.add('extendedColumn');
      currentColumn.classList.add('extendedColumn');
    });
  }, [extendedColumns, resources]);

  useEffect(() => {
    if (showFullCalendar) {
      setCalendarTimeRange({
        start: DateTime.fromJSDate(currentDate).startOf('day').toJSDate(),
        end: DateTime.fromJSDate(currentDate).endOf('day').toJSDate(),
      });
    } else {
      setCalendarTimeRange({
        start: DateTime.fromJSDate(currentDate)
          .set({ hour: 7, minute: 0, second: 0, millisecond: 0 })
          .toJSDate(),
        end: DateTime.fromJSDate(currentDate)
          .set({ hour: 19, minute: 0, second: 0, millisecond: 0 })
          .toJSDate(),
      });
    }
  }, [currentDate, showFullCalendar]);

  useEffect(() => {
    const timeColumns = document.querySelectorAll('.rbc-day-slot');

    timeColumns.forEach((column, index) => {
      const dayOfWeekIndex = Math.floor(index / resources.length) % 7;

      if (dayOfWeekIndex === 5 || dayOfWeekIndex === 6) {
        column.classList.add('weekend');
      }
    });
  }, [resources]);

  return (
    <div className='py-2 px-3 bg-white w-full h-full'>
      <CustomWithDragAndDrop {...calendarProps} />
    </div>
  );
};

type CustomEventProps = {
  event: AppointmentWithDate | PatientVisit;
  title: string;
  isVisitEvent?: boolean;
};

type ToolbarDateComponentProps = {
  date: Date;
};

const ToolbarDateComponent = ({ date }: ToolbarDateComponentProps) => {
  const { start, end } = useMemo(() => {
    const startOfWeek = DateTime.fromJSDate(date).startOf('week');
    const endOfWeek = DateTime.fromJSDate(date).endOf('week');
    return {
      start: startOfWeek.toFormat('LLL dd'),
      end: endOfWeek.hasSame(startOfWeek, 'month')
        ? endOfWeek.toFormat('dd')
        : endOfWeek.toFormat('LLL dd'),
    };
  }, [date]);

  return (
    <div className='h-[36px] min-w-[115px] flex justify-center items-center'>
      <span className='text-product-forest-100 text-md font-medium tracking-tight'>{`${start} - ${end}`}</span>
    </div>
  );
};

const ColumnHeaderComponent = ({ date }: ToolbarDateComponentProps) => {
  const dateTimeDate = useMemo(() => DateTime.fromJSDate(date), [date]);
  const isToday = useMemo(() => {
    const today = DateTime.local();
    return (
      dateTimeDate.year === today.year &&
      dateTimeDate.month === today.month &&
      dateTimeDate.day === today.day
    );
  }, [dateTimeDate.day, dateTimeDate.month, dateTimeDate.year]);

  return (
    <div className='flex flex-col justify-center items-center p-[10px]'>
      <span className='text-[10px] leading-3 uppercase text-product-gray tracking-[0.8px] font-medium'>
        {dateTimeDate.toFormat('ccc')}
      </span>
      <span
        className={clsx(
          'text-fern text-[16px] font-medium leading-7 tracking-tight w-[28px] h-[28px]',
          { 'bg-fern text-white rounded-[50%]': isToday }
        )}
      >
        {dateTimeDate.toFormat('dd')}
      </span>
    </div>
  );
};

type ResourceHeaderComponentProps = {
  label: string;
  resource: Resources;
  isExtendedColumn: boolean;
  uniqueIndex: string;
  handleColumnSizing: (index: number | string, isExtendedColumn: boolean) => void;
};

const ResourceHeaderComponent = ({
  label,
  resource,
  uniqueIndex,
  isExtendedColumn,
  handleColumnSizing,
}: ResourceHeaderComponentProps) => {
  const isClinician = useMemo(
    () => resource.resourceId.includes('provider'),
    [resource.resourceId]
  );

  const isPodOrProvider = useMemo(
    () => resource.resourceId.includes('provider') || resource.resourceId.includes('cmo'),
    [resource.resourceId]
  );

  return (
    <div className='flex flex-col gap-1 mt-2'>
      <ExtendButton
        index={uniqueIndex}
        isPodOrProvider={isPodOrProvider}
        isExtendedColumn={isExtendedColumn}
        handleColumnSizing={handleColumnSizing}
      />

      <div className='flex flex-col p-[10px] text-center text-[10px] font-medium leading-3 tracking-[0.8px] uppercase'>
        <span className='text-off-black text-pretty'>{label}</span>
        {isClinician && <span className='text-product-gray-400 mt-1'>OFFICE</span>}
      </div>
    </div>
  );
};
