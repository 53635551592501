export type SchedulerViewFilters = {
  equipment?: number[];
  personnel?: number[];
  rooms?: number[];
  cmos?: number[];
};

export enum SchedulerViewType {
  DAY = 'DAY',
  WEEK = 'WEEK',
}

export type SchedulerView = {
  id: number;
  name: string;
  type: SchedulerViewType;
  locationId: number;
  filters?: SchedulerViewFilters;
};
