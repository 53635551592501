import { Dropdown } from 'primereact/dropdown';
import { SchedulerView } from '@/@types';
import { SavedViewCountryItem } from '@/components';

import './SavedViewDropdown.css';

type SavedViewDropdownProps = {
  selectedView: SchedulerView | null;
  savedViews: SchedulerView[];
  selectCurrentSchedulerView: (view: SchedulerView | null, forceUpdate?: boolean) => void;
};

export const SavedViewDropdown = ({
  selectedView,
  savedViews,
  selectCurrentSchedulerView,
}: SavedViewDropdownProps) => {
  return (
    <div className='mb-2'>
      <label htmlFor='savedView' className='font-bold text-sm text-kelp pb-2 block'>
        Saved views
      </label>
      <Dropdown
        value={selectedView}
        onChange={(e) => selectCurrentSchedulerView(e.value)}
        options={savedViews}
        optionLabel='name'
        dataKey='id'
        emptyMessage='No views available'
        placeholder='No view selected'
        itemTemplate={SavedViewCountryItem}
        className='saved-view-dropdown'
        name='savedView'
      />
    </div>
  );
};
