import { useState, memo, useCallback, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Editor, EditorTextChangeEvent } from 'primereact/editor';
import { DateTime } from 'luxon';

import { DateTimeHelper } from '@/helpers';
import styles from './DailyNotesDrawer.module.css';
import { DailyNotes } from '@/@types';
import { InputText } from 'primereact/inputtext';
import { env } from '@/utils/constants';
import { ConfirmDeleteDialog } from '../ConfirmDeleteDialog';

type DailyNotesDrawerProps = {
  visible: boolean;
  onHide: () => void;
  currentDate: Date;
  onDailyNotesSave: (newDailyNotes: string, title: string, id?: number) => void;
  selectedDailyNote: Partial<DailyNotes> | null;
  onDelete: (id?: number) => void;
};

export const defaultDailyNoteTitle = 'Daily notes';
export const standardDailyNoteTitles = ['Hospitality', 'Clinicians', 'Radiology'];

export const DailyNotesDrawer = ({
  visible,
  onHide,
  currentDate,
  onDailyNotesSave,
  selectedDailyNote,
  onDelete,
}: DailyNotesDrawerProps) => {
  const luxonDate = DateTime.fromFormat(
    DateTimeHelper.returnOnlyDateYYYYmmDD(currentDate),
    'yyyy-MM-dd'
  ).toFormat('EEEE MMM dd');
  const [editorDailyNotes, setEditorDailyNotes] = useState('');
  const [title, setTitle] = useState('');
  const [showConfirmAppointmentDelete, setShowConfirmAppointmentDelete] = useState(false);

  const handleTextOnChange = useCallback((e: EditorTextChangeEvent) => {
    setEditorDailyNotes(e.htmlValue!);
  }, []);

  const onCancel = useCallback(async () => {
    setEditorDailyNotes('');
    setTitle('');
    onHide();
  }, [setEditorDailyNotes, onHide]);

  const onSave = useCallback(async () => {
    onDailyNotesSave(editorDailyNotes, title, selectedDailyNote?.id);
    onHide();
  }, [title, editorDailyNotes, onHide, onDailyNotesSave, selectedDailyNote]);

  const onClose = useCallback(async () => {
    onHide();
  }, [onHide]);

  useEffect(() => {
    setEditorDailyNotes(selectedDailyNote?.html ?? '');
    setTitle(selectedDailyNote?.title ?? '');
  }, [selectedDailyNote]);

  return (
    <Sidebar
      visible={visible}
      position='right'
      onHide={onHide}
      className={styles.container}
      showCloseIcon={false}
    >
      <header className={styles.header}>
        <h1>Daily notes - {luxonDate}</h1>
        <Button icon='pi pi-times' rounded text aria-label='close' onClick={onClose} />
      </header>
      <main className={styles.content}>
        {env.APP_FEATURE_FLAGS.IS_TO_ENABLE_MULTIPLE_DAILY_NOTES && (
          <InputText
            className='w-full mb-3'
            placeholder='Title'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        )}

        <Editor
          value={editorDailyNotes}
          onTextChange={handleTextOnChange}
          headerTemplate={<RenderHeader />}
          style={{ height: '150px' }}
        />
      </main>
      <footer className={styles.footer}>
        <>
          <Button
            label='Save'
            size='small'
            onClick={onSave}
            className={styles.saveButton}
            disabled={
              !editorDailyNotes ||
              (env.APP_FEATURE_FLAGS.IS_TO_ENABLE_MULTIPLE_DAILY_NOTES && !title)
            }
          />
          {env.APP_FEATURE_FLAGS.IS_TO_ENABLE_MULTIPLE_DAILY_NOTES && selectedDailyNote?.id && (
            <Button
              outlined
              label='Delete'
              size='small'
              onClick={() => setShowConfirmAppointmentDelete(true)}
              className={styles.deleteButton + ' mb-2'}
            />
          )}
          <Button label='Cancel' outlined size='small' onClick={onCancel} />
        </>
      </footer>

      <ConfirmDeleteDialog
        headerText={'Delete daily notes'}
        messageText={'Are you sure you want to delete this note?'}
        visible={showConfirmAppointmentDelete}
        onCancel={() => setShowConfirmAppointmentDelete(false)}
        onHide={() => setShowConfirmAppointmentDelete(false)}
        onConfirm={() => onDelete(selectedDailyNote?.id)}
      />
    </Sidebar>
  );
};

const RenderHeader = memo(
  function RenderHeaderMemoized() {
    return (
      <span className='ql-formats'>
        <button className='ql-bold' aria-label='Bold'></button>
        <button className='ql-italic' aria-label='Italic'></button>
        <button className='ql-underline' aria-label='Underline'></button>
      </span>
    );
  },
  () => true
);
