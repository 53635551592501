import { useCallback, useEffect } from 'react';

import { SchedulerLayout } from '@/components/Layouts';
import { useRooms } from '@/hooks';
import { MockAppointmentsForm } from '@/features/MockAppointments';
import { useVisitTypeTemplates } from '@/features/AppointmentActions';

export const MockAppointmentsPage = () => {
  const { rooms, findAllRooms } = useRooms();
  const { visitTypeTemplates, findAllVisitTypeTemplates } = useVisitTypeTemplates();

  const initialLoad = useCallback(async () => {
    await Promise.all([findAllRooms(), findAllVisitTypeTemplates()]);
  }, [findAllRooms, findAllVisitTypeTemplates]);

  useEffect(() => {
    initialLoad();
  }, [initialLoad]);

  return (
    <SchedulerLayout>
      <div className='p-4'>
        <h1 className='text-xl font-bold'>Mock appointments</h1>
        <MockAppointmentsForm rooms={rooms} visitTypeTemplates={visitTypeTemplates} />
      </div>
    </SchedulerLayout>
  );
};
