import { safeJSONParse } from '@/utils';
import { env } from '@/utils/constants';
import { useEffect } from 'react';
import useWebSocket from 'react-use-websocket';

function isAthenaStatusEvent(message: any) {
  const msg = safeJSONParse(message.data);
  return msg.type === 'ATHENA_STATUS_CHANGED';
}

export type Message = {
  type: string;
  payload: {
    status: number;
  };
};

export const useAthenaStatusSocket = (handleMessage: (data: boolean) => void) => {
  const { lastJsonMessage } = useWebSocket<Message>(env.APP_SOCKET_URL, {
    share: true,
    filter: isAthenaStatusEvent,
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    reconnectInterval: (times) => times * 10000,
  });

  useEffect(() => {
    if (lastJsonMessage) {
      const athenaStatus = lastJsonMessage.payload;
      handleMessage(athenaStatus.status === 200);
    }
  }, [lastJsonMessage, handleMessage]);
};
