import {
  AppointmentEventType,
  AppointmentType,
  Personnel,
  Room,
  RoomName,
  RoomType,
  SelectOption,
} from '@/@types';

import {
  AppointmentProvider,
  AppointmentWithDate,
  Atria,
  GoogleEventsWithDate,
  PatientVisit,
  Resources,
} from '@/@types';
import { DateTime } from 'luxon';
import { checkIfValidProviderAppointment, mountAppointmentObject } from '@/utils';

const groupedRooms = [
  RoomName.Hudson,
  RoomName.Bond,
  RoomName.Crosby,
  RoomName.LeRoy,
  RoomName.Mercer,
];

export const DEFAULT_GOOGLE_EVENT_TITLE = 'Google Calendar';

const shouldDisplayBackgroundEventInRooms = (
  uniqueRoomsList: Partial<Room>[],
  columnRoom: Partial<Room>
) => {
  if (
    columnRoom.type === null ||
    columnRoom.type === RoomType.IMAGING ||
    columnRoom.type === RoomType.P_AND_M ||
    columnRoom.type === RoomType.CARDIAC
  )
    return false;

  const hasRoomGroup = groupedRooms.some((roomName) =>
    uniqueRoomsList.some((room) => room.name === roomName)
  );
  if (
    hasRoomGroup &&
    (columnRoom.name === RoomName.Bleecker || columnRoom.name === RoomName.Broadway)
  ) {
    return false;
  }

  const isSuiteRoom = columnRoom.type === RoomType.SUITE;
  const isPedsRoom = columnRoom.type === RoomType.PEDS;
  const hasSuiteRoom = uniqueRoomsList.some((room) => room.type === RoomType.SUITE);
  const hasPedsRoom = uniqueRoomsList.some((room) => room.type === RoomType.PEDS);

  if (!hasSuiteRoom && !hasPedsRoom && columnRoom.type === RoomType.EXAM_ROOM) {
    return true;
  }

  return isSuiteRoom || isPedsRoom;
};

type BackgroundEvent = {
  isVisitEvent: boolean;
  appointments: AppointmentWithDate[];
  uniqueRoomsList: Partial<Room>[];
  liaison: AppointmentProvider | undefined;
};

const getAppointmentsPerPatientId = (appointmentsGroupByPatientId: {
  [key: string]: Atria.Appointment[];
}): BackgroundEvent[] =>
  Object.keys(appointmentsGroupByPatientId)
    .map((patientId: string) => {
      const rooms: Map<number, Room> = new Map();
      let liaison: AppointmentProvider | undefined;

      const appointments = appointmentsGroupByPatientId[Number(patientId)]
        .sort((a, b) => {
          if (DateTime.fromISO(a.date) < DateTime.fromISO(b.date)) {
            return -1;
          }
          return 0;
        })
        .map((appointment) => {
          appointment.rooms.forEach((room) => {
            rooms.set(room.id, <Room>room);
          });

          if (!liaison) {
            liaison = appointment.providers?.find((provider) => provider.type === 'LIAISON');
          }

          return mountAppointmentObject(appointment);
        });

      return {
        isVisitEvent: true,
        appointments,
        uniqueRoomsList: Array.from(rooms, ([, room]) => room) as Partial<Room>[],
        liaison,
      };
    })
    .filter((patientVisit) => patientVisit.appointments.length > 0);

const getGoogleAppointmentsByProviderId = (
  googleCalendarAppointments: GoogleEventsWithDate[]
): { [key: string]: GoogleEventsWithDate[] } =>
  googleCalendarAppointments.reduce((result: { [key: string]: GoogleEventsWithDate[] }, event) => {
    (result[event.providerId] = result[event.providerId] || []).push(event);
    return result;
  }, {});

const getVisitsByDay = ({
  patientVisit,
  resource,
  personnel,
  hidePatientName,
}: {
  patientVisit: BackgroundEvent;
  resource: string;
  personnel: Personnel[];
  hidePatientName: boolean;
}) => {
  const [appt] = patientVisit.appointments;
  const primaryProviderId = appt.patientPrimaryProviderId?.toString() || '';
  const cmo = personnel.find(
    (p) => p.resourceId === 'provider-' + primaryProviderId?.toString()
  )?.resourceTitle;

  const appointmentsGroupedByDay = patientVisit.appointments.reduce(
    (result: { [key: string]: AppointmentWithDate[] }, appointment) => {
      const key = appointment.date.getDay();
      if (!result[key]) {
        result[key] = [];
      }

      result[key].push(appointment);
      return result;
    },
    {}
  );

  return Object.keys(appointmentsGroupedByDay).map((day) => {
    const firstAppointment = appointmentsGroupedByDay[day].sort(
      (a, b) => a.date.getTime() - b.date.getTime()
    )[0];
    const appointments = appointmentsGroupedByDay[day];
    const lastEnd = appointments.sort((b, a) => a.end.getTime() - b.end.getTime())[0].end;
    return {
      title: `${firstAppointment.firstNameUsed && !hidePatientName ? `${firstAppointment.firstNameUsed} ${firstAppointment.lastName}` : firstAppointment.patientName!}`,
      date: DateTime.fromJSDate(firstAppointment.date).minus({ minutes: 15 }).toJSDate(),
      end: DateTime.fromJSDate(lastEnd).toJSDate(),
      resourceId: resource,
      liaison: patientVisit.liaison,
      cmo,
      isVisitEvent: patientVisit.isVisitEvent,
      appointments: appointments
        .map((apt) => ({
          ...apt,
          resourceId: resource,
        }))
        // @ts-expect-error: Return will be a number
        .sort((a, b) => DateTime.fromJSDate(a.date) - DateTime.fromJSDate(b.date)),
    };
  });
};

const getVisit = ({
  patientVisit,
  resource,
  personnel,
  hidePatientName,
}: {
  patientVisit: BackgroundEvent;
  resource: string;
  personnel: Personnel[];
  hidePatientName: boolean;
}) => {
  const [appointment] = patientVisit.appointments;
  const primaryProviderId = appointment.patientPrimaryProviderId?.toString() || '';
  const cmo = personnel.find(
    (p) => p.resourceId === 'provider-' + primaryProviderId?.toString()
  )?.resourceTitle;
  const lastEnd = patientVisit.appointments.sort((b, a) => a.end.getTime() - b.end.getTime())[0]
    .end;
  return {
    title: `${appointment.firstNameUsed && !hidePatientName ? `${appointment.firstNameUsed} ${appointment.lastName}` : appointment.patientName!}`,
    date: DateTime.fromJSDate(appointment.date).minus({ minutes: 15 }).toJSDate(),
    end: DateTime.fromJSDate(lastEnd).toJSDate(),
    resourceId: resource,
    liaison: patientVisit.liaison,
    cmo,
    isVisitEvent: patientVisit.isVisitEvent,
    appointments: patientVisit.appointments
      .map((appt) => ({
        ...appt,
        resourceId: resource,
      }))
      // @ts-expect-error: Return will be a number
      .sort((a, b) => DateTime.fromJSDate(a.date) - DateTime.fromJSDate(b.date)),
  };
};

const checkIfResourcesContains = (resources: Resources[], type: string): boolean =>
  !!resources.find((resource) => resource.resourceId.includes(type));

const groupAppointmentsByPatientId = (appointments: Atria.Appointment[]) => {
  return appointments.reduce((result: { [key: string]: Atria.Appointment[] }, appointment) => {
    const key = appointment.patientId;
    if (key) {
      if (!result[key]) {
        result[key] = [];
      }

      result[key].push(appointment);
    }
    return result;
  }, {});
};

const createBackgroundEventsForPatientWeeklyAppointments = ({
  patientVisitsForAllResources,
  resources,
  personnel,
  hidePatientName,
}: {
  patientVisitsForAllResources: BackgroundEvent[];
  resources: Resources[];
  personnel: Personnel[];
  hidePatientName: boolean;
}): PatientVisit[] => {
  const patientVisitsList: PatientVisit[] = [];
  // TODO: background event logic for each one, not just one for all
  const isDisplayingCmoResourceColumn = checkIfResourcesContains(resources, 'cmo');
  const isDisplayingProviderResourceColumn = checkIfResourcesContains(resources, 'provider');

  patientVisitsForAllResources.forEach((patientVisit) => {
    if (isDisplayingCmoResourceColumn) {
      const visits = getVisitsByDay({
        patientVisit,
        resource: `cmo-${patientVisit.appointments[0].patientPrimaryProviderId}`,
        personnel,
        hidePatientName,
      });

      patientVisitsList.push(
        ...visits.map((visit) => ({
          ...visit,
          resourceId: `cmo-${patientVisit.appointments[0].patientPrimaryProviderId}`,
        }))
      );
    }
    if (isDisplayingProviderResourceColumn) {
      const visits = getVisitsByDay({
        patientVisit,
        resource: `provider-${patientVisit.appointments[0].providerId}`,
        personnel,
        hidePatientName,
      });

      patientVisitsList.push(
        ...visits.map((visit) => ({
          ...visit,
          resourceId: `provider-${patientVisit.appointments[0].providerId}`,
        }))
      );
    }
  });
  return patientVisitsList;
};

const checkIfAthenaOnlyAppointments = (patientVisit: BackgroundEvent) =>
  patientVisit.uniqueRoomsList.length === 0 &&
  patientVisit.appointments.every((appointment) => !appointment.atriaAppointment) &&
  patientVisit.appointments.every((appointment) => checkIfValidProviderAppointment(appointment));

const createBackgroundEventsForPatientAppointments = ({
  patientVisitsForRooms,
  patientVisitsForAllResources,
  resources,
  personnel,
  hidePatientName,
}: {
  patientVisitsForRooms: BackgroundEvent[];
  patientVisitsForAllResources: BackgroundEvent[];
  resources: Resources[];
  personnel: Personnel[];
  hidePatientName: boolean;
}): PatientVisit[] => {
  const patientVisitsList: PatientVisit[] = [];
  // TODO: background event logic for each one, not just one for all
  const isDisplayingCmoResourceColumn = checkIfResourcesContains(resources, 'cmo');
  const isDisplayingProviderResourceColumn = checkIfResourcesContains(resources, 'provider');
  patientVisitsForRooms.forEach((patientVisit) => {
    patientVisit.uniqueRoomsList.forEach((room) => {
      if (!shouldDisplayBackgroundEventInRooms(patientVisit.uniqueRoomsList, room)) return;
      const visit = getVisit({
        patientVisit,
        resource: `room-${room.id}`,
        personnel,
        hidePatientName,
      });
      patientVisitsList.push(visit);
      // TODO: remove it from here, thinking in a solution that will not depends on rooms associate to show in cmo/provider
      // also need to check if a primary provider and cmo is associated to the appt
      if (isDisplayingCmoResourceColumn) {
        patientVisitsList.push({
          ...visit,
          // TODO: no need repeat it in everyplace
          resourceId: `cmo-${patientVisit.appointments[0].patientPrimaryProviderId}`,
        });
      }
      if (isDisplayingProviderResourceColumn) {
        patientVisitsList.push({
          ...visit,
          resourceId: `provider-${patientVisit.appointments[0].providerId}`,
        });
      }
    });
  });
  patientVisitsForAllResources.forEach((patientVisit) => {
    const isAthenaOnlyAppointments = checkIfAthenaOnlyAppointments(patientVisit);
    // TODO: also improve here, that logic of only show if all the appointments are in athena for that patientId/date
    // maybe is not correct
    // also need to check if a primary provider and cmo is associated to the appt
    if (!isAthenaOnlyAppointments) {
      return;
    }

    if (isDisplayingCmoResourceColumn) {
      const visit = getVisit({
        patientVisit,
        resource: `cmo-${patientVisit.appointments[0].patientPrimaryProviderId}`,
        personnel,
        hidePatientName,
      });
      patientVisitsList.push({
        ...visit,
        resourceId: `cmo-${patientVisit.appointments[0].patientPrimaryProviderId}`,
      });
    }
    if (isDisplayingProviderResourceColumn) {
      const visit = getVisit({
        patientVisit,
        resource: `provider-${patientVisit.appointments[0].providerId}`,
        personnel,
        hidePatientName,
      });
      patientVisitsList.push({
        ...visit,
        resourceId: `provider-${patientVisit.appointments[0].providerId}`,
      });
    }
  });
  return patientVisitsList;
};

const createBackgroundEventsForGoogleAppointments = (googleAppointmentsByProviderId: {
  [key: string]: GoogleEventsWithDate[];
}): PatientVisit[] =>
  Object.keys(googleAppointmentsByProviderId).map((providerId) => {
    const appointments = googleAppointmentsByProviderId[providerId] as (AppointmentWithDate &
      AppointmentEventType)[];
    const minStartDate = appointments.sort((a, b) => a.date.getTime() - b.date.getTime())[0].date;
    const maxEndDate = appointments.sort((a, b) => a.end.getTime() - b.end.getTime())[
      appointments.length - 1
    ].end;
    return {
      title: DEFAULT_GOOGLE_EVENT_TITLE,
      date: DateTime.fromJSDate(minStartDate).minus({ minutes: 15 }).toJSDate(),
      end: DateTime.fromJSDate(maxEndDate).toJSDate(),
      resourceId: `provider-${providerId}`,
      isVisitEvent: true,
      appointments: appointments.map((appt) => ({
        ...appt,
        resourceId: `provider-${providerId}`,
      })),
      uniqueRoomsList: [],
      liaison: undefined,
    };
  });

enum relatedRoomsEnum {
  Columbus = 4,
  Amsterdam = 5,
  ExamRomm1 = 7,
  ExamRomm2 = 8,
  UltrasoundSuite = 45,
  Hudson = 842,
}

enum appointmentTypeEnum {
  Ultrasound = 127,
}

const skipRoomForAppointmentType: { [key: number]: { inRoomId: number; skip: number[] }[] } = {
  [appointmentTypeEnum.Ultrasound]: [
    {
      inRoomId: relatedRoomsEnum.Hudson,
      skip: [relatedRoomsEnum.ExamRomm2, relatedRoomsEnum.UltrasoundSuite],
    },
  ],
};

const AmsterdamAndColumbusAppointmentTypes = [2, 65, 69, 71, 143, 161, 182, 361, 521, 781, 861];

const roomsRelatedTypes: { [key: number]: number[] } = {
  [relatedRoomsEnum.Amsterdam]: AmsterdamAndColumbusAppointmentTypes,
  [relatedRoomsEnum.Columbus]: AmsterdamAndColumbusAppointmentTypes,
};

const relatedRooms: { [key: number]: number[] } = {
  [relatedRoomsEnum.Amsterdam]: [relatedRoomsEnum.ExamRomm1],
  [relatedRoomsEnum.Columbus]: [relatedRoomsEnum.ExamRomm2],
};

const shouldSkipRoomForSpecificType = (
  roomId: number,
  appointmentType: AppointmentType,
  addedRoomId: number
) => {
  const conditions = skipRoomForAppointmentType[appointmentType.id];

  if (conditions) {
    const filteredConditions = conditions.filter((condition) => condition.inRoomId === roomId);
    if (filteredConditions.some((condition) => condition.skip.includes(addedRoomId))) {
      return true;
    }
  }

  return false;
};

const getRelatedNewRooms = (
  selectedRooms: SelectOption[],
  roomsList: SelectOption[],
  appointmentType?: AppointmentType | null
) => {
  const resultRooms = [...selectedRooms];

  selectedRooms.forEach((currentSelected) => {
    if (
      appointmentType &&
      roomsRelatedTypes[currentSelected.id] &&
      !roomsRelatedTypes[currentSelected.id].includes(appointmentType.id)
    ) {
      return;
    }

    const relations = relatedRooms[currentSelected.id];
    const newRoomsList = roomsList.filter(
      (r) => relations?.includes(r.id) && !selectedRooms.find((sr) => sr.id === r.id)
    );
    if (newRoomsList) {
      resultRooms.push(...newRoomsList);
    }
  });

  return resultRooms;
};

const getAppointmentsGroupedByRoom = (appointments: Atria.Appointment[]) => {
  const roomsAppointments = new Map<string, Atria.Appointment[]>();

  for (const appointment of appointments) {
    for (const room of appointment.rooms) {
      const existingAppointments = roomsAppointments.get(room.name);
      if (existingAppointments) {
        existingAppointments.push(appointment);
      } else {
        roomsAppointments.set(room.name, [appointment]);
      }
    }
  }

  return roomsAppointments;
};

const getUniqueAppointmentsList = (appointmentsSelected: AppointmentWithDate[]) => {
  const uniquesIds = [...new Set(appointmentsSelected.map((appt) => appt.id))];
  const appointments = uniquesIds.map((appointmentId) => {
    const apptSelected = appointmentsSelected.find(({ id }) => id === appointmentId)!;
    return {
      id: appointmentId,
      atriaAppointment: apptSelected.atriaAppointment,
      roomsIds: apptSelected.rooms?.map((room) => room.id) ?? [],
    };
  });
  return appointments;
};

export const SchedulerHelper = {
  createBackgroundEventsForGoogleAppointments,
  createBackgroundEventsForPatientAppointments,
  getAppointmentsPerPatientId,
  getGoogleAppointmentsByProviderId,
  checkIfResourcesContains,
  groupAppointmentsByPatientId,
  getRelatedNewRooms,
  getAppointmentsGroupedByRoom,
  getUniqueAppointmentsList,
  shouldSkipRoomForSpecificType,
  createBackgroundEventsForPatientWeeklyAppointments,
};
