import { DateTime } from 'luxon';

const now = DateTime.now();

export function getAppointmentEndTime(date?: Date) {
  let startTime: DateTime = now.startOf('minute').plus({ minutes: 15 - (now.minute % 15) + 15 });

  if (date) {
    startTime = DateTime.fromJSDate(date);
  }

  return startTime.toFormat('T');
}
