import { ReactNode, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import MobileDetect from 'mobile-detect';
import { useLocation as routerUseLocation, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { loader } from 'react-global-loader';
import { useOktaAuth } from '@okta/okta-react';
import { motion } from 'framer-motion';

import { Region } from '@/@types';
import AtriaWord from '@/assets/atria-word-stone.svg';
import { env } from '@/utils/constants';

import { authService } from '@/services/authService';
import { useMockAppointments } from '@/features/MockAppointments/hooks';
import { useToastContext } from '@/contexts';

type NavbarProps = {
  locationOptions?: Region[];
  regionSelected?: number;
  headerComponent?: ReactNode;
  shouldShowLocationSelection?: boolean;
  changeRegion?: (regionId: number) => void;
  currentDate?: string;
};

export const Navbar = ({
  locationOptions = [],
  regionSelected,
  headerComponent,
  shouldShowLocationSelection = true,
  changeRegion,
  currentDate,
}: NavbarProps) => {
  const navigate = useNavigate();
  const { toast } = useToastContext();
  const { randomlyGenerateAppointments } = useMockAppointments();
  const isMenuEnabled = useMemo(
    () =>
      env.APP_FEATURE_FLAGS.IS_TO_ENABLE_APPOINTMENT_SEARCH_LINK ||
      env.APP_FEATURE_FLAGS.IS_TO_ENABLE_MOCK_APPOINTMENTS,
    []
  );

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const handleNavigate = useCallback(
    (href: string) => {
      navigate(href);
    },
    [navigate]
  );

  const handleMockAppointments = useCallback(async () => {
    loader.show();

    try {
      await randomlyGenerateAppointments(currentDate);

      toast?.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Appointments created',
        life: 3000,
      });
    } catch {
      toast?.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to create appointments',
        life: 3000,
      });
    } finally {
      loader.hide();
    }
  }, [currentDate, randomlyGenerateAppointments, toast]);

  const menuItems = useMemo(
    (): MenuItems[] => [
      {
        label: 'Scheduler',
        icon: 'today',
        onClick: () => handleNavigate('/'),
        href: '/',
        visible: true,
      },
      {
        label: 'Search Appointments',
        icon: 'search',
        onClick: () => handleNavigate('/appointments'),
        href: '/appointments',
        visible: env.APP_FEATURE_FLAGS.IS_TO_ENABLE_APPOINTMENT_SEARCH_LINK,
      },
      {
        label: 'Mock Appointments',
        icon: 'library_add',
        onClick: () => handleMockAppointments(),
        visible: env.APP_FEATURE_FLAGS.IS_TO_ENABLE_MOCK_APPOINTMENTS,
      },
    ],
    [handleMockAppointments, handleNavigate]
  );

  const onLocationChange = useCallback(
    (event: DropdownChangeEvent) => {
      const regionId = event.target.value;
      if (changeRegion) {
        changeRegion(regionId);
      }
    },
    [changeRegion]
  );

  const onMenuToggle = useCallback(() => {
    setIsMenuVisible((prev) => !prev);
  }, []);

  return (
    <div className='h-[64px] p-[var(--space-regular)] bg-[var(--fern)] flex justify-between items-center'>
      <div className='flex items-center'>
        {isMenuEnabled && (
          <>
            <Button onClick={onMenuToggle}>
              <i className='material-icons-outlined'>menu</i>
            </Button>
            <MenuSidebar visible={isMenuVisible} menuItems={menuItems} />
          </>
        )}

        <img src={AtriaWord} alt='Atria Logo' className='h-[32px] mx-4' />
        {shouldShowLocationSelection && !!locationOptions.length && (
          <Dropdown
            className='md:ml-5 ml-0 bg-transparent border hover:border-white h-11 flex items-center
          [&_*]:text-white [&_.p-dropdown-label]:pr-0 [&_*]:font-bold [&_*]:text-sm [&_.p-dropdown-trigger]:w-10'
            options={locationOptions}
            value={regionSelected}
            onChange={onLocationChange}
            dropdownIcon='pi pi-globe'
            optionLabel='name'
            optionValue='id'
            filter={false}
          />
        )}
      </div>
      {headerComponent}
      <SingOut />
    </div>
  );
};

type MenuItems = {
  label: string;
  onClick: () => void;
  visible: boolean;
  href?: string;
  icon?: string;
};

const MenuSidebar = ({ visible, menuItems }: { visible: boolean; menuItems: MenuItems[] }) => {
  const mobileDetect = new MobileDetect(window.navigator.userAgent);
  const isMobile = mobileDetect.mobile();
  const { oktaAuth } = useOktaAuth();
  const location = routerUseLocation();

  const logout = async () => {
    await authService.deleteCookieAccess();
    await oktaAuth.signOut();
  };

  return (
    <motion.aside
      variants={{
        open: { x: 0 },
        closed: { x: isMobile ? -500 : -250 },
      }}
      transition={{ damping: 40 }}
      animate={visible ? 'open' : 'closed'}
      className='fixed top-[64px] left-0 z-10 w-full md:w-[250px] shadow-2xl bg-product-forest-100 h-[calc(100vh-64px)] py-4 flex flex-col'
    >
      {menuItems.map(
        (menuItem) =>
          menuItem.visible && (
            <button
              key={menuItem.label}
              className={classNames(
                'py-6 px-4 w-full text-sm flex items-center gap-2 text-white cursor-pointer bg-product-forest-100 hover:bg-fern/[0.5] z-20',
                { 'bg-fern/[0.5]': location.pathname === menuItem.href }
              )}
              onClick={menuItem.onClick}
            >
              <i className='material-icons-outlined'>{menuItem.icon}</i>
              <span className='font-bold'>{menuItem.label}</span>
            </button>
          )
      )}
      <button
        className='py-6 px-4 w-full text-sm flex items-center gap-2 text-white cursor-pointer bg-product-forest-100 hover:bg-fern/[0.5] z-20 self-end mt-auto'
        onClick={logout}
      >
        <i className='material-icons-outlined'>logout</i>
        <span className='font-bold'>Logout</span>
      </button>
    </motion.aside>
  );
};

const SingOut = () => {
  const { oktaAuth } = useOktaAuth();

  const logout = async () => {
    await authService.deleteCookieAccess();
    await oktaAuth.signOut();
  };

  return (
    <Button
      className='text-white max-[400px]:text-[0px] max-[400px]:mr-0'
      label='Logout'
      size='small'
      onClick={logout}
    />
  );
};
