import { Component } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore:next-line
import TimeGridHeader from 'react-big-calendar/lib/TimeGridHeader';

interface TimeGridHeaderProps {
  range: any[];
  events: any[];
  resources?: Record<string, any>;
  getNow: () => Date;
  isOverflowing?: boolean;
  rtl?: boolean;
  resizable?: boolean;
  width?: number;
  localizer: Record<string, any>;
  accessors: Record<string, any>;
  components: Record<string, any>;
  getters: Record<string, any>;
  selected?: Record<string, any>;
  selectable?: true | false | 'ignoreEvents';
  longPressThreshold?: number;
  allDayMaxRows?: number;
  onSelectSlot?: () => void;
  onSelectEvent?: () => void;
  onDoubleClickEvent?: () => void;
  onKeyPressEvent?: () => void;
  onDrillDown?: () => void;
  onShowMore?: () => void;
  getDrilldownView: () => void;
  scrollRef?: any;
}

interface TimeGridHeaderState {}

declare class ITimeGridHeader extends Component<TimeGridHeaderProps, TimeGridHeaderState> {
  renderEvents(): void;
}

interface ITimeGridHeaderConstructor {
  new (props: TimeGridHeaderProps, state: TimeGridHeaderState): ITimeGridHeader;
}

export default TimeGridHeader as ITimeGridHeaderConstructor;
