import { ReactNode } from 'react';

import { ToastProvider } from './toastContext';
import { AuthProvider } from './authContext';

export * from './authContext';
export * from './appointmentsContext';
export * from './appointmentTypeContext';
export * from './filterContext';
export * from './locationContext';
export * from './schedulerContexts';
export * from './schedulerViewSettingsContext';
export * from './toastContext';

export function AppContexts({ children }: { children: ReactNode }) {
  return (
    <ToastProvider>
      <AuthProvider>{children}</AuthProvider>
    </ToastProvider>
  );
}
