import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'primereact/button';

import {
  Cmo,
  FiltersProp,
  OnFilterChange,
  PatientOption,
  Personnel,
  ProviderEquipment,
  ProviderRoom,
  Resources,
  SchedulerViewType,
} from '@/@types';
import { useToastContext } from '@/contexts';
import { useSchedulerSavedViewContext } from '@/features/Calendar/contexts/schedulerSavedViewContext';
import { SchedulerFilterHelper } from '@/helpers';
import { env } from '@/utils/constants';

import { SelectFilterWrapper } from '../SelectFilterWrapper';
import { SavedViewDropdown } from '@/features';
import classNames from 'classnames';

type SchedulerFiltersProps = {
  roomsOptions: ProviderRoom[];
  patientOptions: PatientOption[];
  onResourcesChange: (resources: Resources[]) => void;
  filters: FiltersProp;
  setFilterItem: (key: keyof FiltersProp, value: any[]) => void;
  equipmentFilterList: ProviderEquipment[];
  providersFilterList: Personnel[];
  cmosFilterList: Cmo[];
};

export const SchedulerFilters = ({
  roomsOptions,
  patientOptions,
  filters,
  equipmentFilterList,
  providersFilterList,
  cmosFilterList,
  setFilterItem,
  onResourcesChange,
}: SchedulerFiltersProps) => {
  // TODO: we can remove the state (setSchedulerFilters) and use the filters prop directly
  const [schedulerFilters, setSchedulerFilters] = useState<Resources[]>([]);
  const { selectedView, savedViews, selectCurrentSchedulerView, saveViewForUser } =
    useSchedulerSavedViewContext();
  const [isSaveSchedulerViewInProgress, setIsSaveSchedulerViewInProgress] = useState(false);
  const { toast } = useToastContext();

  const dailySavedViews = useMemo(
    () => savedViews.filter((sv) => sv.type === SchedulerViewType.DAY),
    [savedViews]
  );

  const clearFilters = useCallback(() => {
    setFilterItem('rooms', []);
    setFilterItem('patients', []);
    setFilterItem('equipment', []);
    setFilterItem('cmos', []);
    if (filters.personnel.length) {
      setFilterItem('personnel', []);
    }
    onResourcesChange([]);
  }, [filters.personnel.length, setFilterItem, onResourcesChange]);

  const handleOnFiltersChange = useCallback(
    ({ key, value }: OnFilterChange) => {
      const previousFiltersIds = schedulerFilters.map(({ resourceId }) => resourceId);
      const newFilter = value.filter(({ resourceId }) => !previousFiltersIds.includes(resourceId));
      let newFilterList: Resources[] = [];
      if (newFilter.length) {
        newFilterList = [...schedulerFilters, ...newFilter];
      } else {
        const newIds = value.map(({ resourceId }) => resourceId);
        const removeFilterItem = filters[key]
          ?.filter(({ resourceId }) => !newIds.includes(resourceId))
          .map(({ resourceId }) => resourceId);
        newFilterList = schedulerFilters.filter(
          ({ resourceId }) => !removeFilterItem.includes(resourceId)
        );
      }
      onResourcesChange(newFilterList);
      setFilterItem(key, value);
    },
    [schedulerFilters, setFilterItem, onResourcesChange, filters]
  );

  const handleOnFiltersSort = useCallback(
    ({ key, value }: OnFilterChange) => {
      setFilterItem(key, value);
    },
    [setFilterItem]
  );

  const roomsOptionsGrouped = useMemo(() => {
    return SchedulerFilterHelper.groupOptions<ProviderRoom>(
      roomsOptions.sort((a, b) => a.displayOrder - b.displayOrder),
      { groupByField: 'type' }
    );
  }, [roomsOptions]);

  const clinicianOptionsGrouped = useMemo(() => {
    return SchedulerFilterHelper.groupOptions<Personnel>(
      providersFilterList.sort((a, b) => a.resourceTitle.localeCompare(b.resourceTitle)),
      { groupByField: 'specialty' }
    );
  }, [providersFilterList]);

  const isToEnableClearFilters = useMemo(
    () =>
      filters.cmos.length ||
      filters.personnel.length ||
      filters.rooms.length ||
      filters.patients.length ||
      filters.equipment.length,
    [
      filters.cmos.length,
      filters.personnel.length,
      filters.rooms.length,
      filters.patients.length,
      filters.equipment.length,
    ]
  );

  const isToShowSaveViewButton = useMemo(
    () =>
      !!(
        (!selectedView || !savedViews?.length) &&
        (filters.cmos.length ||
          filters.personnel.length ||
          filters.rooms.length ||
          filters.equipment.length)
      ),
    [
      selectedView,
      savedViews?.length,
      filters.cmos.length,
      filters.personnel.length,
      filters.rooms.length,
      filters.equipment.length,
    ]
  );

  const addSavedView = useCallback(async () => {
    setIsSaveSchedulerViewInProgress(true);
    try {
      const viewCreated = await saveViewForUser({
        rooms: filters.rooms.map((room) => Number(room.resourceId.split('-')[1])),
        cmos: filters.cmos.map((cmo) => Number(cmo.resourceId.split('-')[1])),
        personnel: filters.personnel.map((personnel) => Number(personnel.resourceId.split('-')[1])),
        equipment: filters.equipment.map((equipment) => Number(equipment.resourceId.split('-')[1])),
      });
      if (viewCreated) {
        selectCurrentSchedulerView(viewCreated, true);
      }
    } catch (error) {
      toast?.current?.show({
        severity: 'error',
        summary: 'Failed to create a new saved view',
        detail: `Try again. If error persists, message Slack channel #scheduler-buildout or contact Teresa Rufin`,
        life: 8000,
      });
    } finally {
      setIsSaveSchedulerViewInProgress(false);
    }
  }, [
    toast,
    filters.rooms,
    filters.cmos,
    filters.personnel,
    filters.equipment,
    saveViewForUser,
    selectCurrentSchedulerView,
  ]);

  useEffect(() => {
    setSchedulerFilters([
      ...filters.rooms,
      ...filters.cmos,
      ...filters.personnel,
      ...filters.equipment,
      ...filters.patients,
    ]);
  }, [filters]);

  return (
    <aside className='px-1 mb-6'>
      {!env.APP_FEATURE_FLAGS.IS_TO_SHOW_WEEKLY_CALENDAR && <h3 className='mb-6'>Daily View</h3>}
      <SavedViewDropdown
        selectedView={selectedView}
        selectCurrentSchedulerView={selectCurrentSchedulerView}
        savedViews={dailySavedViews}
      />

      <div className={classNames({ 'mt-3': !env.APP_FEATURE_FLAGS.IS_TO_SHOW_WEEKLY_CALENDAR })}>
        <SelectFilterWrapper
          title='Rooms'
          label='resourceTitle'
          {...(env.APP_FEATURE_FLAGS.IS_TO_GROUP_ROOMS_FILTER
            ? {
                options: roomsOptionsGrouped,
                optionGroupLabel: 'label',
                optionGroupChildren: 'options',
                optionGroupIdentify: 'resourceId',
              }
            : {
                options: roomsOptions,
                dataKey: 'resourceId',
              })}
          onChange={(value) => handleOnFiltersChange({ key: 'rooms', value })}
          placeholder='Select'
          value={roomsOptions?.length ? filters.rooms : null}
          dataKey='resourceId'
          sortable
          onSort={(value) => handleOnFiltersSort({ key: 'rooms', value })}
        />
        <SelectFilterWrapper
          title='Pod'
          label='resourceTitle'
          options={cmosFilterList.sort((a, b) => a.resourceTitle.localeCompare(b.resourceTitle))}
          onChange={(value) => handleOnFiltersChange({ key: 'cmos', value })}
          placeholder='Select'
          dataKey='resourceId'
          value={filters.cmos}
          sortable
          onSort={(value) => handleOnFiltersSort({ key: 'cmos', value })}
        />
        <SelectFilterWrapper
          title='Clinician'
          label='resourceTitle'
          {...(env.APP_FEATURE_FLAGS.IS_TO_GROUP_CLINICIAN_FILTER
            ? {
                options: clinicianOptionsGrouped,
                optionGroupLabel: 'label',
                optionGroupChildren: 'options',
                optionGroupIdentify: 'resourceId',
              }
            : {
                options: providersFilterList,
                dataKey: 'resourceId',
              })}
          onChange={(value) => handleOnFiltersChange({ key: 'personnel', value })}
          placeholder='Select'
          value={providersFilterList?.length ? filters.personnel : null}
          sortable
          onSort={(value) => handleOnFiltersSort({ key: 'personnel', value })}
        />
        <SelectFilterWrapper
          title='Resources'
          label='resourceTitle'
          options={equipmentFilterList.sort((a, b) =>
            a.resourceTitle.localeCompare(b.resourceTitle)
          )}
          onChange={(value) => handleOnFiltersChange({ key: 'equipment', value })}
          placeholder='Select'
          dataKey='resourceId'
          value={equipmentFilterList?.length ? filters.equipment : null}
          sortable
          onSort={(value) => handleOnFiltersSort({ key: 'equipment', value })}
        />
        <SelectFilterWrapper
          title='Patient name'
          label='patientName'
          dataKey='resourceId'
          options={patientOptions.sort((a, b) => a.resourceTitle.localeCompare(b.resourceTitle))}
          onChange={(value) => handleOnFiltersChange({ key: 'patients', value })}
          placeholder='Select'
          value={patientOptions?.length ? filters.patients : null}
          sortable
          onSort={(value) => handleOnFiltersSort({ key: 'patients', value })}
        />
        <Button
          disabled={isSaveSchedulerViewInProgress || !isToShowSaveViewButton}
          onClick={addSavedView}
          label='Save view'
          size='small'
          type='submit'
          className='mt-4 w-full'
        />
        <Button
          disabled={!isToEnableClearFilters}
          label='Clear filters'
          size='small'
          className='mt-4 w-full p-button p-button-outlined'
          onClick={clearFilters}
        />
      </div>
    </aside>
  );
};
