import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore:next-line
import DayColumn from 'react-big-calendar/lib/DayColumn';

interface DayColumnProps {
  events: any[];
  backgroundEvents: any[];

  step: number;
  date: Date;
  min: Date;
  max: Date;
  getNow: () => Date;
  isNow?: boolean;

  rtl?: boolean;
  resizable?: boolean;

  accessors: Record<string, any>;
  components: Record<string, any>;
  getters: Record<string, any>;
  localizer: Record<string, any>;

  showMultiDayTimes?: boolean;
  culture?: string;
  timeslots?: number;

  selected?: any;
  selectable?: true | false | 'ignoreEvents';
  eventOffset?: number;
  longPressThreshold?: number;

  onSelecting?: (slotInfo: any) => void;
  onSelectSlot: (slotInfo: any) => void;
  onSelectEvent: (event: any) => void;
  onDoubleClickEvent: (event: any) => void;
  onKeyPressEvent?: (event: any) => void;

  className?: string;
  dragThroughEvents?: boolean;
  resource?: any;

  dayLayoutAlgorithm?: 'overlap' | 'no-overlap' | (() => void);
}

export default DayColumn as React.ComponentType<DayColumnProps>;
