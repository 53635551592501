import { Component } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore:next-line
import TimeGutter from 'react-big-calendar/lib/TimeGutter';

interface TimeGutterProps {
  min: Date;
  max: Date;
  timeslots: number;
  step: number;
  getNow: () => Date;
  components: Record<string, any>;
  getters?: Record<string, any>;

  localizer: Record<string, any>;
  resource?: string;
  gutterRef?: any;
}

interface TimeGutterState {}

export declare class ITimeGutter extends Component<TimeGutterProps, TimeGutterState> {}

interface ITimeGutterConstructor {
  new (props: TimeGutterProps, state: TimeGutterState): ITimeGutter;
}

export default TimeGutter as ITimeGutterConstructor;
