import classNames from 'classnames';

type FormFieldProps = React.PropsWithChildren & {
  disabled?: boolean;
  error?: string;
  firstColumn: boolean;
  inline?: boolean;
  isRequired: boolean;
  label: string;
  labelRef: string;
  reverted?: boolean;
};

export const FormField = ({
  children,
  disabled = false,
  error,
  firstColumn,
  inline = false,
  isRequired,
  label,
  labelRef,
  reverted = false,
}: FormFieldProps) => {
  return (
    <div
      className={classNames('flex flex-1', {
        'ml-5': !firstColumn,
        'flex-col items-stretch': !inline,
        'flex-row items-center justify-start gap-2': inline,
      })}
    >
      {reverted && children}
      <label
        htmlFor={labelRef}
        className={classNames('text-xs font-bold', {
          'text-kelp': !error && !disabled,
          'text-rust': !!error,
          'mb-2': !inline,
          'text-[var(--gray-3)]': disabled,
        })}
      >
        {label}
        {isRequired ? '*' : ''}
      </label>
      {!reverted && children}
      {error && <span className='text-xs text-rust'>{error}</span>}
    </div>
  );
};
