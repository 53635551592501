import { Component, LegacyRef, ReactNode } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore:next-line
import TimeGrid from 'react-big-calendar/lib/TimeGrid';
import { ITimeGutter } from '@/features/Calendar/lib/react-big-calendar/lib/TimeGutter';

interface TimeGridProps {
  events: any[];
  backgroundEvents: any[];
  resources?: any[];

  step?: number;
  timeslots?: number;
  range: Date[];
  min: Date;
  max: Date;
  getNow: () => Date;

  scrollToTime: Date;
  enableAutoScroll?: boolean;
  showMultiDayTimes?: boolean;

  rtl?: boolean;
  resizable?: boolean;
  width?: number;

  accessors: Record<string, any>;
  components: Record<string, any>;
  getters: Record<string, any>;
  localizer: Record<string, any>;

  allDayMaxRows?: number;

  selected?: Record<string, any>;
  selectable?: true | false | 'ignoreEvents';
  longPressThreshold?: number;

  onNavigate?: () => void;
  onSelectSlot: (slotInfo: any) => void;
  onSelectEnd?: () => void;
  onSelectStart?: () => void;
  onSelectEvent: (event: any) => void;
  onShowMore?: () => void;
  onDoubleClickEvent: () => void;
  onKeyPressEvent?: () => void;
  onDrillDown?: () => void;
  getDrilldownView: () => void;

  dayLayoutAlgorithm?: 'overlap' | 'no-overlap' | (() => void);

  showAllEvents?: boolean;
  doShowMoreDrillDown?: boolean;

  popup?: boolean;
  handleDragStart?: () => void;

  popupOffset?: number | { x: number; y: number };
}

interface TimeGridState {
  selecting: boolean;
  timeIndicatorPosition: any;
  gutterWidth: number;
  isOverflowing?: boolean;
}

export declare class ITimeGrid extends Component<TimeGridProps, TimeGridState> {
  containerRef: LegacyRef<HTMLDivElement>;
  contentRef: LegacyRef<HTMLDivElement>;
  scrollRef: LegacyRef<HTMLDivElement>;
  gutterRef: LegacyRef<ITimeGutter>;
  renderEvents(range: any[], events: any[], backgroundEvents: any[], now: Date): any;
  memoizedResources(
    resources?: any[],
    accessors?: Record<string, any>
  ): { map: Function; groupEvents: Function };
  handleSelectAllDaySlot(): void;
  handleSelectEvent(): void;
  handleShowMore(): void;
  handleScroll(): void;
  renderOverlay(): ReactNode;
}

interface ITimeGridConstructor {
  new (props: TimeGridProps, state: TimeGridState): ITimeGrid;
}

export default TimeGrid as ITimeGridConstructor;
