import { Outlet } from 'react-router-dom';
import { LocationContext } from '.';
import { ActionHistoryContext, ProvidersContext, RoomsContext } from '@/hooks';
import { AppointmentTypeContext } from './appointmentTypeContext';
import { AppointmentActionsContext } from '@/features/AppointmentActions';
import { SchedulerSavedViewContext } from '@/features/Calendar/contexts/schedulerSavedViewContext';

export function SchedulerContexts() {
  return (
    <ActionHistoryContext>
      <RoomsContext>
        <ProvidersContext>
          <AppointmentTypeContext>
            <LocationContext>
              <SchedulerSavedViewContext>
                <AppointmentActionsContext>
                  <Outlet />
                </AppointmentActionsContext>
              </SchedulerSavedViewContext>
            </LocationContext>
          </AppointmentTypeContext>
        </ProvidersContext>
      </RoomsContext>
    </ActionHistoryContext>
  );
}
