import { useCallback } from 'react';
import { Atria, Patient, Room, VisitTypeTemplates } from '@/@types';
import { SchedulerHelper } from '@/helpers';
import { DateTime } from 'luxon';
import {
  appointmentsService,
  providersService,
  roomsService,
  visitTypeTemplatesService,
} from '@/services';
import { getRandomInt, getRandomTime, getRandomVisitTypesTemplates } from '../utils';

type CreateMockedAppointmentsProps = {
  date: Date;
  patient: Patient;
  rooms: Room[];
  roomsOptionsList: Room[];
  visitTypeTemplates: VisitTypeTemplates[];
};

const isEquipmentProvider = 'Non-person entity';

export const useMockAppointments = () => {
  const createMockedAppointments = useCallback(
    async ({
      visitTypeTemplates,
      date,
      patient,
      rooms,
      roomsOptionsList,
    }: CreateMockedAppointmentsProps) => {
      const events = getAppointmentsFromVisitTypeTemplates(
        visitTypeTemplates,
        rooms,
        roomsOptionsList,
        date,
        patient
      );

      try {
        const results = await createAppointments(events);
        return results;
      } catch {
        throw new Error('Failed to create appointments');
      }
    },
    []
  );

  const randomlyGenerateAppointments = useCallback(async (currentDate?: string) => {
    const [{ data: visitTypeTemplates }, { data: rooms }, { data: providers }] = await Promise.all([
      visitTypeTemplatesService.findAll(),
      roomsService.findAll(),
      providersService.findAll(),
    ]);

    const suiteRooms = rooms.filter((r) => r.isSuite);
    const events: Atria.CreateAppointment.Body[][] = [];

    const date = currentDate
      ? DateTime.fromFormat(currentDate, 'MM-dd-yyyy').toJSDate()
      : new Date();

    Array.from({ length: 10 }, () => {
      const currentStartTime = getRandomTime(date).toJSDate();
      const randomPatient = {
        id: getRandomInt(1, 100),
        firstName: 'John',
        lastName: 'Doe',
        patientName: 'John Doe',
        dob: '01/01/1991',
      };

      const randomVisitTemplateTypes = getRandomVisitTypesTemplates(visitTypeTemplates);
      const shuffledSuiteRooms = suiteRooms.sort(() => 0.5 - Math.random());
      const shuffledProviders = providers
        .filter((provider) => provider.entityType !== isEquipmentProvider)
        .sort(() => 0.5 - Math.random());

      const randomEvents = getAppointmentsFromVisitTypeTemplates(
        randomVisitTemplateTypes,
        [shuffledSuiteRooms[0]],
        rooms,
        currentStartTime,
        randomPatient
      );

      events.push(
        randomEvents.map((event) => {
          const provider = shuffledProviders[getRandomInt(0, shuffledProviders.length)];

          return {
            ...event,
            providerId: provider.id,
            providerName: `${provider.firstName || ''} ${provider.lastName || ''}`.trim(),
          };
        })
      );
    });

    await Promise.all(events.map((event) => createAppointments(event)));
  }, []);

  return {
    createMockedAppointments,
    randomlyGenerateAppointments,
  };
};

async function createAppointments(events: Atria.CreateAppointment.Body[]) {
  const createMultipleAppointments: Atria.CreateMultipleAppointments.Body = {
    appointments: events,
  };

  const { data: results } = await appointmentsService.createMultipleAppointments(
    createMultipleAppointments
  );

  return results;
}

function getAppointmentsFromVisitTypeTemplates(
  visitTypeTemplates: VisitTypeTemplates[],
  rooms: Room[],
  roomsOptionsList: Room[],
  startTime: Date,
  patient: Patient
) {
  const events: Atria.CreateAppointment.Body[] = [];
  let currentStartTime = DateTime.fromJSDate(startTime);

  for (const event of visitTypeTemplates) {
    let eventRooms = [...rooms];

    if (event.defaultRooms) {
      eventRooms = [
        ...eventRooms,
        ...roomsOptionsList.filter((r) =>
          event.defaultRooms.map((defaultRoom) => defaultRoom.id).includes(r.id)
        ),
      ];
    }

    const relatedRooms = SchedulerHelper.getRelatedNewRooms(
      eventRooms.map((er) => ({ id: er.id, label: er.name })),
      roomsOptionsList.map((er) => ({ id: er.id, label: er.name }))
    );

    events.push({
      date: currentStartTime.toUTC().toISO()!,
      duration: event.duration,
      rooms: relatedRooms.map((r) => ({ id: r.id })),
      title: event.name,
      patient,
      providers: event.defaultEquipment.map((e) => ({
        id: e.id,
        name: e.name,
        type: 'EQUIPMENT',
      })),
      ...(event.defaultType && {
        type: event.defaultType.name,
        typeId: event.defaultType.id,
      }),
    });

    currentStartTime = currentStartTime.plus({ minutes: event.duration });
  }

  return events;
}
