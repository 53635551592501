import { AppointmentProvider, SelectOption, Atria as AtriaType } from '.';

export enum Origin {
  Atria,
  Athena,
  Google,
}

export const OriginLabel = {
  [Origin.Atria]: 'Atria Scheduler',
  [Origin.Athena]: 'Athena',
  [Origin.Google]: 'Google Calendar',
};

export enum ConflictSource {
  GOOGLE = 'GOOGLE',
  ATRIA = 'ATRIA',
}

export const ConflictSourceLabel = {
  [ConflictSource.ATRIA]: 'Atria',
  [ConflictSource.GOOGLE]: 'Google',
};

export enum AppointmentAuditAction {
  CREATE_APPOINTMENT = 'CREATE_APPOINTMENT',
  DELETE_APPOINTMENT = 'DELETE_APPOINTMENT',
  UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT',
  RESTORE_APPOINTMENT = 'RESTORE_APPOINTMENT',
}

export enum AppointmentAuditActionToDialogTitle {
  CREATE_APPOINTMENT = 'Appointment Created',
  DELETE_APPOINTMENT = 'Appointment Deleted',
  UPDATE_APPOINTMENT = 'Appointment Edited',
  RESTORE_APPOINTMENT = 'Appointment Restored',
}

export enum ConfirmedTypes {
  FULLY_CONFIRMED = 'FULLY_CONFIRMED',
  HOLD_CONFIRMED = 'HOLD_CONFIRMED',
  UNCONFIRMED = 'UNCONFIRMED',
}

export const confirmedTypes = ['FULLY_CONFIRMED', 'HOLD_CONFIRMED', 'UNCONFIRMED'] as const;

export type ConfirmedType = (typeof confirmedTypes)[number];

export type Appointment = {
  id: number;
  appointmentId: number;
  date: string;
  end: string;
  duration: number;
  title: string;
  description?: string | null;
  type: string;
  typeId: number;
  providerId?: number;
  resourceId?: string;
  statusId: any;
  patientId?: number;
  patientPrimaryProviderId?: number | null;
  patientName?: string;
  firstName?: string;
  lastName?: string;
  atriaAppointment: boolean;
  provider: SelectOption;
  providers: SelectOption[];
  rooms: { id: number; label: string }[];
  confirmed: ConfirmedType;
  checkIn?: string;
  checkOut?: string;
};

export type AppointmentParsed = {
  id: number;
  appointmentId: number;
  date: Date;
  end: Date;
  title: string;
  duration: number;
  description?: string;
  type?: string | null;
  typeId?: number | null;
  providerId?: number | null;
  providerName?: string | null;
  patientId?: number | null;
  patientPrimaryProviderId?: number | null;
  patientName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  firstNameUsed?: string | null;
  atriaAppointment: boolean;
  provider: SelectOption;
  providers?: AppointmentProvider[];
  rooms?: SelectOption[];
  confirmed: ConfirmedType;
  checkIn?: string | null;
  checkOut?: string | null;
  conferenceLink?: string;
  externalClient?: boolean;
  origin: Origin;
  hasPatientVisit?: boolean;
  updatedBy: string;
  updatedAt: Date;
  athenaAppointmentId?: number | null;
  athenaDepartmentId?: number | null;
  hasConflict?: boolean;
  address?: string;
  addressDetails?: string;
  addressNumber?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
  latitude?: string;
  longitude?: string;
  conflicts?: AppointmentConflict[];
  googleCalendarEventId?: string | null;
};

export type AppointmentWithDate = AppointmentParsed & {
  resourceId?: string;
};

export type AppointmentWithResourcesList = AppointmentParsed & {
  resourceId?: string[];
};

export type AppointmentConflict = {
  resourceId?: number;
  resourceName?: string;
  appointmentId: number;
  source: string;
};

export type AppointmentEventType = {
  isOutOfOffice?: boolean;
  isAllDay?: boolean;
  workingLocationType?: string;
};

export type AppointmentsByPatient = {
  [key: number]: AtriaType.Appointment[];
};

export type PatientVisit = {
  title?: string;
  date?: Date;
  end?: Date;
  resourceId?: string;
  appointments: AppointmentWithDate[];
  liaison?: AppointmentProvider;
  cmo?: string | null;
  isVisitEvent: boolean;
};

export type AppointmentsSearch = {
  rangeQueryStart: string;
  rangeQueryEnd: string;
  providersIds?: string;
  patientsIds?: string;
  appointmentTypesIds?: string;
};

export const isPatientVisit = (patient: any | PatientVisit): patient is PatientVisit => {
  return 'isVisitEvent' in patient;
};
