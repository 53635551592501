import { Atria } from '@/@types';
import { Toast } from 'primereact/toast';
import { MutableRefObject } from 'react';

function showNotificationIfNotSincronized(
  appointmentResult: Atria.Appointment,
  syncParams: {
    syncToAthena: boolean | null | undefined;
    syncToGoogle: boolean | null | undefined;
  },
  toast: MutableRefObject<Toast> | undefined
) {
  const errorMessage = getMessage();

  if (errorMessage) {
    return toast?.current?.show({
      severity: 'error',
      summary: 'Error',
      detail: `Appointment was not created in ${errorMessage}. Please edit and save the appointment to try again.`,
      life: 3000,
    });
  }

  function getMessage(): string | undefined {
    if (!isAthenaSyncronized() && !isGoogleSyncronized()) {
      return 'both Google Calendar and Athena';
    } else if (!isAthenaSyncronized()) {
      return 'Athena';
    } else if (!isGoogleSyncronized()) {
      return 'Google Calendar';
    }
  }

  function isAthenaSyncronized(): boolean {
    if (!syncParams.syncToAthena) return true;
    return !!appointmentResult.athenaAppointmentId;
  }

  function isGoogleSyncronized(): boolean {
    if (!syncParams.syncToGoogle) return true;
    return !!appointmentResult.googleCalendarEventId;
  }
}

const messageSyncGoogleAndAthena = `
Unconfirmed and confirmed appointments will appear in the member's portal upon saving.
Appointments that have "Add to Athena" or "Add to Google Calendar" checked will be
published in the system upon saving.
`;

export const SyncHelper = {
  showNotificationIfNotSincronized,
  messageSyncGoogleAndAthena,
};
