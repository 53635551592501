import { MouseEvent, useCallback, useState } from 'react';
import { useToastContext } from '@/contexts';
import { useSchedulerSavedViewContext } from '@/features/Calendar/contexts/schedulerSavedViewContext';
import { SAVED_VIEWS_PRESETS } from '@/data/data';

export const SavedViewCountryItem = (option: { name: string; id: number }) => {
  const { handleDeleteSchedulerView, handleUpdateSchedulerViewName } =
    useSchedulerSavedViewContext();
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [isDeleteStep, setIsDeleteStep] = useState(false);
  const [isEditStep, setIsEditStep] = useState(false);
  const [schedulerViewNameForEdit, setSchedulerViewNameForEdit] = useState('');
  const { toast } = useToastContext();

  const handleDeleteView = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    setIsDeleteStep(true);
  }, []);
  const handleCancelEditName = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    setIsEditStep(false);
  }, []);

  const handleEditViewName = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      setSchedulerViewNameForEdit(option.name);
      setIsEditStep(true);
    },
    [option.name]
  );

  const handleSaveEditViewName = useCallback(
    async (event: MouseEvent) => {
      event.stopPropagation();
      setIsEditLoading(true);
      try {
        await handleUpdateSchedulerViewName(option.id, schedulerViewNameForEdit);
      } catch (error) {
        toast?.current?.show({
          severity: 'error',
          summary: 'Failed to edit the saved view name',
          detail: `Try again. If error persists, message Slack channel #scheduler-buildout or contact Teresa Rufin`,
          life: 7000,
        });
      } finally {
        setIsEditLoading(false);
        setIsEditStep(false);
      }
    },
    [handleUpdateSchedulerViewName, option.id, schedulerViewNameForEdit, toast]
  );

  const handleDeleteConfirmed = useCallback(
    async (event: MouseEvent) => {
      event.stopPropagation();

      handleDeleteSchedulerView(option.id);

      setIsDeleteStep(false);
    },
    [handleDeleteSchedulerView, option.id]
  );

  const handleDeleteDenied = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    setIsDeleteStep(false);
  }, []);

  return (
    <div className='flex justify-between items-center py-0 px-2 h-[36px]'>
      {isDeleteStep && (
        <>
          <div className='flex justify-between'>
            <div className='color-[#626262] font-medium'>Delete view?</div>
          </div>
          <div className='flex py-1'>
            <button
              className='text-[#626262] font-bold text-xs p-1.5 px-2 hover:bg-product-grey-400 hover:text-white rounded-full'
              onClick={handleDeleteDenied}
            >
              Cancel
            </button>
            <button
              className='text-[#C87575] font-bold text-xs p-1.5 px-2 ml-2 bg-rust hover:bg-blush rounded-full'
              onClick={handleDeleteConfirmed}
            >
              Yes
            </button>
          </div>
        </>
      )}
      {isEditStep && (
        <>
          <input
            autoFocus
            className='!outline-none border border-[#C4C4C4] text-sm rounded-md my-1 py-0.5 px-2 w-36'
            placeholder='view name'
            value={schedulerViewNameForEdit}
            onClick={(e) => e.stopPropagation()}
            onInput={(e) => setSchedulerViewNameForEdit(e.currentTarget.value)}
          />
          <div className='flex py-1'>
            <button
              className={`pi pi-check text-[#3C4E3D] text-sm py-1 px-2 ml-2 hover:bg-fern hover:text-white rounded-full ' + ${isEditLoading ? 'pi-spin pi-spinner' : 'pi-check'}`}
              onClick={handleSaveEditViewName}
              disabled={isEditLoading}
              title='Save new view name'
            ></button>

            <button
              className='pi pi-times text-[#3C4E3D] text-sm p-1 px-2 hover:bg-rust hover:text-white rounded-full'
              onClick={handleCancelEditName}
              disabled={isEditLoading}
              title='Cancel edit view name'
            ></button>
          </div>
        </>
      )}
      {!isEditStep && !isDeleteStep && (
        <>
          <div className='flex justify-between'>
            <div className='color-[#626262] font-medium'>{option.name}</div>
          </div>
          {!SAVED_VIEWS_PRESETS.map(({ id }) => id).includes(option.id) && (
            <div className='flex py-1'>
              <button
                className='pi pi-pencil text-[#3C4E3D] text-sm p-1 px-2 hover:bg-fern hover:text-white rounded-full'
                onClick={handleEditViewName}
                title='Edit view name'
              ></button>
              <button
                className='pi pi-times text-[#3C4E3D] text-sm p-1 px-2 hover:bg-rust hover:text-white rounded-full'
                onClick={handleDeleteView}
                title='Delete view'
              ></button>
            </div>
          )}
        </>
      )}
    </div>
  );
};
