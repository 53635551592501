import { Atria } from '@/@types';
import { api } from '@/api';
import { env } from '@/utils/constants';
import axios from 'axios';

const placesApi = axios.create({
  baseURL: 'https://places.googleapis.com',
});

function findAllEvents(params: Atria.FindAllGoogleEvents.Params) {
  return api.get<Atria.FindAllGoogleEvents.Response>('/api/v1/google/calendar/events', { params });
}

function getToken(body: Atria.GetGoogleToken.Params) {
  return api.post<Atria.GetGoogleToken.Response>('/api/v1/google/auth', body);
}

function getRefreshToken(body: Atria.GetGoogleRefreshToken.Params) {
  return api.post<Atria.GetGoogleRefreshToken.Response>('/api/v1/google/auth/refresh-token', body);
}

function getAddresses(input: string) {
  return placesApi.post<{
    suggestions: {
      placePrediction: {
        placeId: string;
        text: {
          text: string;
        };
      };
    }[];
  }>(
    '/v1/places:autocomplete',
    {
      input,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Goog-Api-Key': env.APP_GOOGLE_MAPS_API_KEY,
      },
    }
  );
}

function getAddressDetails(placeId: string) {
  return placesApi.get<{
    addressComponents: { types: string[]; longText: string }[];
    location: {
      latitude: number;
      longitude: number;
    };
  }>('/v1/places/' + placeId, {
    headers: {
      'Content-Type': 'application/json',
      'X-Goog-Api-Key': env.APP_GOOGLE_MAPS_API_KEY,
      'X-Goog-FieldMask': '*',
    },
  });
}

function getAddress(textQuery: string) {
  return placesApi.post<{
    places: {
      id: string;
      addressComponents: { types: string[]; longText: string }[];
      formattedAddress: string;
    }[];
  }>(
    '/v1/places:searchText',
    {
      textQuery,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Goog-Api-Key': env.APP_GOOGLE_MAPS_API_KEY,
        'X-Goog-FieldMask': '*',
      },
    }
  );
}

export const googleService = {
  findAllEvents,
  getToken,
  getRefreshToken,
  getAddresses,
  getAddressDetails,
  getAddress,
};
