import { zodResolver } from '@hookform/resolvers/zod';
import { DateTime } from 'luxon';
import * as z from 'zod';
import { AppointmentFormBaseSchema, AppointmentTimeSchema } from './AppointmentCommonFormSchema';

const EditAppointmentWithTimeFormSchema = AppointmentFormBaseSchema.and(AppointmentTimeSchema)
  .refine(
    ({ date, start, end }) => {
      const dateStart = DateTime.fromFormat(`${date} ${start}`, 'M/d/yy T');
      const dateEnd = DateTime.fromFormat(`${date} ${end}`, 'M/d/yy T');
      if (!dateEnd.isValid || !dateStart.isValid) return false;

      const endTimeMin = dateStart.plus({ minute: 15 });

      return dateEnd >= endTimeMin;
    },
    {
      message: 'The end time must be at least 15 minutes ahead of the start time',
      path: ['end'],
    }
  )
  .refine(
    ({ atriaAppointment, roomsSelected }) => {
      return !atriaAppointment || (atriaAppointment && roomsSelected.length > 0);
    },
    {
      message: 'Please select a room',
      path: ['roomsSelected'],
    }
  );

export type EditAppointmentWithTimeFormType = z.infer<typeof EditAppointmentWithTimeFormSchema>;
export const EditAppointmentWithTimeFormResolver = zodResolver(EditAppointmentWithTimeFormSchema);
