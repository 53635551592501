import { useCallback, useEffect, useState } from 'react';
import { loader } from 'react-global-loader';
import { DateTime } from 'luxon';
import { Skeleton } from 'primereact/skeleton';
import { AppointmentFilters, AppointmentList } from '@/components';
import { useAppointments, useProviders, useAppointmentTypes } from '@/hooks';
import {
  AppointmentFilterResourceItem,
  AppointmentFiltersChange,
  AppointmentsSearch,
  Atria,
} from '@/@types';
import { useToastContext } from '@/contexts';
import { SchedulerLayout } from '@/components/Layouts';

const mappIds = (filters: AppointmentFilterResourceItem[]): string => {
  return filters!.map((m) => m.resourceId)?.join(',');
};

const convertDateToDateTimeObject = (date: Date) => {
  return DateTime.local(date!.getFullYear(), date!.getMonth() + 1, date!.getDate());
};

const getRangeDate = (startDateItem: Date | undefined, endDateItem: Date | undefined) => {
  const initialDate = startDateItem
    ? convertDateToDateTimeObject(startDateItem).startOf('day').toUTC().toString()
    : DateTime.fromFormat('1/1/2021', 'd/m/yyyy').startOf('day').toUTC().toString();
  const lastDate = endDateItem
    ? convertDateToDateTimeObject(endDateItem).endOf('day').toUTC().toString()
    : DateTime.now().plus({ year: 2 }).startOf('day').toUTC().toString();

  return {
    rangeQueryStart: initialDate,
    rangeQueryEnd: lastDate,
  };
};

export function AppointmentsPage() {
  const [appointments, setAppointments] = useState<Atria.Appointment[] | null>(null);
  const { appointmentTypesOptionsList, getAppointmentTypes } = useAppointmentTypes();
  const { providersList, findAllProviders } = useProviders();
  const [skeletonAnimation, setSkeletonAnimation] = useState<'wave' | 'none' | undefined>('wave');
  const [skeletonHidden, setSkeletonHidden] = useState(true);
  const { toast } = useToastContext();

  const { searchAppointments } = useAppointments();

  const listAppointments = useCallback(
    async (filters: AppointmentFiltersChange) => {
      const payload: AppointmentsSearch = {
        ...(filters.appointmentTypes?.length
          ? { appointmentTypeIds: mappIds(filters.appointmentTypes) }
          : {}),
        ...(filters.providers?.length ? { providersIds: mappIds(filters.providers) } : {}),
        ...(filters.patientId ? { patientsIds: filters.patientId } : {}),
        ...getRangeDate(filters.startDate, filters.endDate),
      };

      const appointmentList = await searchAppointments(payload);

      setAppointments(appointmentList);
    },
    [searchAppointments]
  );

  const onResourcesChange = useCallback(
    async (filters: AppointmentFiltersChange) => {
      try {
        setSkeletonAnimation('wave');
        setSkeletonHidden(false);
        await listAppointments(filters);
      } catch (error) {
        setAppointments(null);
        toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to get appointments',
          life: 3000,
        });
      } finally {
        setSkeletonHidden(true);
      }
    },
    [listAppointments, toast]
  );

  useEffect(() => {
    async function initialState() {
      loader.show();
      await Promise.all([findAllProviders(), getAppointmentTypes()]);
      loader.hide();
    }
    initialState();
  }, [findAllProviders, getAppointmentTypes]);

  return (
    <SchedulerLayout isLocationRequired={false}>
      <div className='flex flex-col lg:flex-row'>
        <div className='min-w-[450px] lg:max-w-[450px] p-6 lg:ml-5'>
          <AppointmentFilters
            providersOptionsList={providersList.map((i) => ({
              resourceId: i.resourceId.replace('provider-', ''),
              resourceTitle: i.resourceTitle,
            }))}
            appointmentTypesOptionsList={appointmentTypesOptionsList.map((i) => ({
              resourceId: i.id.toString(),
              resourceTitle: i.name,
            }))}
            onResourcesChange={onResourcesChange}
          />
        </div>
        <div className={'m-4 lg:w-2/4 lg:max-w-7xl lg:max-h-[calc(100vh-8rem)]'}>
          <h1 className='font-sans text-kelp font-semibold text-2xl mt-4'>
            Results {appointments?.length ? `(${appointments.length})` : ''}
          </h1>
          {skeletonHidden ? (
            <AppointmentList appointmentList={appointments} classNames='shadow-lg mt-5' />
          ) : (
            <div className='m-4 w-full'>
              <Skeleton
                className='flex lg:max-w-7xl h-[350px]'
                animation={skeletonAnimation}
              ></Skeleton>
            </div>
          )}
        </div>
      </div>
    </SchedulerLayout>
  );
}
